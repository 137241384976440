import React from "react";
import ReactDOM from "react-dom";

const Modal = ({ children }) => {
  const element = document.getElementsByClassName("justwidget--inner")[0];

  if (!element) return null;

  return ReactDOM.createPortal(
    <div className="justwidget--modal--wrapper">
      <div className="justwidget--modal">{children}</div>
    </div>,
    element
  );
};

export default Modal;
