import React from 'react';
import FileInfoIcon from '../../icons/FileInfoIcon';
import DownloadIcon from '../../icons/DownloadIcon';

export default function FileMessage({ url, name: rawName }) {
  const name = rawName || decodeURI(url).split('/').reverse()[0];
  return (
    <div className="justwidget--file-message">
      <a
        href={url}
        download
        style={{ marginLeft: 0 }}
        target="_blank"
        rel="noopener noreferrer"
        className="justwidget--file-message-icon"
      >
        <FileInfoIcon />
        <DownloadIcon />
      </a>
      <span className="justwidget--file-name">{name}</span>
    </div>
  );
}
