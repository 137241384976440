import React, {Component} from 'react';
import DnDIcon from "../icons/DnDIcon";

export default class DnDArea extends Component {
    dropRef = React.createRef();

    componentDidMount() {
        let div = this.dropRef.current;
        if (!div) return;
        div.addEventListener('drop', this.handleDrop);
        div.addEventListener('dragover', this.preventDefault);
    }
    componentWillUnmount() {
        let div = this.dropRef.current;
        if (!div) return;
        div.removeEventListener('drop', this.handleDrop);
        div.removeEventListener('dragover', this.preventDefault);
    }
    preventDefault = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {wrapperRef, fileRef, uploadFile} = this.props;
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            fileRef.files = e.dataTransfer.files;
            uploadFile();
            wrapperRef.classList.remove('justwidget--dnd-container');
        }
    }

    render() {
        const {wrapperRef, label} = this.props;
        return <div
            ref={this.dropRef}
            onDragLeave={() => wrapperRef.classList.remove('justwidget--dnd-container') }
            onMouseLeave={() => wrapperRef.classList.remove('justwidget--dnd-container')}
            className="justwidget--file-dnd">
            <DnDIcon/>
            <span style={{pointerEvents: 'none'}}>{label}</span>
        </div>
    }

}