import React from 'react';

function UserAvatar() {
    return <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.5 20.25C36.5 29.2246 29.2246 36.5 20.25 36.5C11.2754 36.5 4 29.2246 4 20.25C4 11.2754 11.2754 4 20.25 4C29.2246 4 36.5 11.2754 36.5 20.25Z" fill="url(#paint0_radial_useravatar)"/>
        <g filter="url(#filter0_fuseravatar)">
            <path d="M35.5 20.25C35.5 28.6723 28.6723 35.5 20.25 35.5C11.8277 35.5 5 28.6723 5 20.25C5 11.8277 11.8277 5 20.25 5C28.6723 5 35.5 11.8277 35.5 20.25Z" fill="url(#paint1_linear_useravatar)"/>
        </g>
        <path d="M36.5 20.25C36.5 29.2246 29.2246 36.5 20.25 36.5C11.2754 36.5 4 29.2246 4 20.25C4 11.2754 11.2754 4 20.25 4C29.2246 4 36.5 11.2754 36.5 20.25Z" fill="url(#paint2_linear_useravatar)" fillOpacity="0.4"/>
        <path d="M36.5 20.25C36.5 29.2246 29.2246 36.5 20.25 36.5C11.2754 36.5 4 29.2246 4 20.25C4 11.2754 11.2754 4 20.25 4C29.2246 4 36.5 11.2754 36.5 20.25Z" fill="url(#paint3_linear_useravatar)" fillOpacity="0.4"/>
        <g filter="url(#filter1_fuseravatar)">
            <path d="M34.5 20.25C34.5 28.1201 28.1201 34.5 20.25 34.5C12.3799 34.5 6 28.1201 6 20.25C6 12.3799 12.3799 6 20.25 6C28.1201 6 34.5 12.3799 34.5 20.25Z" fill="url(#paint4_linear_useravatar)" fillOpacity="0.95"/>
        </g>
        <g filter="url(#filter2_fuseravatar)">
            <path d="M29.5 21.25C29.5 26.3586 25.3586 30.5 20.25 30.5C15.1414 30.5 11 26.3586 11 21.25C11 16.1414 15.1414 12 20.25 12C25.3586 12 29.5 16.1414 29.5 21.25Z" fill="#F7E14E" fillOpacity="0.9"/>
        </g>
        <path d="M17 16.75C17 18.2688 16.2165 19.5 15.25 19.5C14.2835 19.5 13.5 18.2688 13.5 16.75C13.5 15.2312 14.2835 14 15.25 14C16.2165 14 17 15.2312 17 16.75Z" fill="#6A300E"/>
        <g filter="url(#filter3_fuseravatar)">
            <path d="M16.125 16.7498C16.125 17.7624 15.7332 18.5832 15.25 18.5832C14.7668 18.5832 14.375 17.7624 14.375 16.7498C14.375 15.7373 14.7668 14.9165 15.25 14.9165C15.7332 14.9165 16.125 15.7373 16.125 16.7498Z" fill="#B0733D"/>
        </g>
        <path d="M27 16.75C27 18.2688 26.2165 19.5 25.25 19.5C24.2835 19.5 23.5 18.2688 23.5 16.75C23.5 15.2312 24.2835 14 25.25 14C26.2165 14 27 15.2312 27 16.75Z" fill="#6A300E"/>
        <g filter="url(#filter4_fuseravatar)">
            <path d="M26.125 16.7503C26.125 17.7628 25.7332 18.5837 25.25 18.5837C24.7668 18.5837 24.375 17.7628 24.375 16.7503C24.375 15.7378 24.7668 14.917 25.25 14.917C25.7332 14.917 26.125 15.7378 26.125 16.7503Z" fill="#B0733D"/>
        </g>
        <path d="M26.1552 25.0818C24.217 26.5802 18.6937 28.1911 14.3465 25.0415C14.2032 24.9377 14.0001 25.0402 14 25.2208C14 25.2788 14.0233 25.3355 14.0636 25.376C18.707 30.0477 24.8471 27.4752 26.4624 25.3616C26.4878 25.3284 26.5 25.2885 26.5 25.2462C26.5001 25.0731 26.2896 24.9779 26.1552 25.0818Z" fill="#6B3613"/>
        <defs>
            <filter id="filter0_fuseravatar" x="4" y="4" width="32.5" height="32.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.5" result="effect1_fuseravataroregroundBlur"/>
            </filter>
            <filter id="filter1_fuseravatar" x="5" y="5" width="30.5" height="30.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.5" result="effect1_fuseravataroregroundBlur"/>
            </filter>
            <filter id="filter2_fuseravatar" x="5" y="6" width="30.5" height="30.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="3" result="effect1_fuseravataroregroundBlur"/>
            </filter>
            <filter id="filter3_fuseravatar" x="12.375" y="12.9165" width="5.75" height="7.66667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1" result="effect1_fuseravataroregroundBlur"/>
            </filter>
            <filter id="filter4_fuseravatar" x="22.375" y="12.917" width="5.75" height="7.66667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1" result="effect1_fuseravataroregroundBlur"/>
            </filter>
            <radialGradient id="paint0_radial_useravatar" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.25 20.25) rotate(90) scale(16.25)">
                <stop offset="0.791197" stopColor="#EABB32"/>
                <stop offset="0.97702" stopColor="#CB6414"/>
            </radialGradient>
            <linearGradient id="paint1_linear_useravatar" x1="20.25" y1="5" x2="20.25" y2="35.5" gradientUnits="userSpaceOnUse">
                <stop offset="0.256986" stopColor="#EDBB35"/>
                <stop offset="1" stopColor="#CB6414"/>
            </linearGradient>
            <linearGradient id="paint2_linear_useravatar" x1="20.25" y1="4" x2="20.25" y2="36.5" gradientUnits="userSpaceOnUse">
                <stop offset="0.290672" stopColor="#EABB32"/>
                <stop offset="1" stopColor="#DD7C1C"/>
            </linearGradient>
            <linearGradient id="paint3_linear_useravatar" x1="20.25" y1="4" x2="20.25" y2="36.5" gradientUnits="userSpaceOnUse">
                <stop offset="0.290672" stopColor="#EABB32"/>
                <stop offset="1" stopColor="#DD7C1C"/>
            </linearGradient>
            <linearGradient id="paint4_linear_useravatar" x1="20.25" y1="6" x2="20.25" y2="34.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="0.368838" stopColor="white" stopOpacity="0.5"/>
                <stop offset="0.725487" stopColor="white" stopOpacity="0.1"/>
                <stop offset="0.982024" stopColor="white" stopOpacity="0.05"/>
            </linearGradient>
        </defs>
    </svg>

}

export default React.memo(UserAvatar);

