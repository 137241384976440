export const commonLocalization = {
  "captions.inputPlaceholder": {
    eng: "Enter your query",
    ru: "Введите текст запроса",
    pt: "Digite sua consulta",
  },
  "captions.loadHistory": {
    eng: "Load history",
    ru: "Загрузить историю сообщений",
    pt: "Carregar histórico",
  },
  "captions.errorOccurred": {
    eng: "An error occurred",
    ru: "Возникла ошибка",
    pt: "Ocorreu um erro",
  },
  "captions.viewLog": {
    eng: "view log",
    ru: "посмотреть лог ошибки",
    pt: "ver log",
  },
  "captions.errorLog": {
    eng: "Error log",
    ru: "Лог ошибки",
    pt: "Log de erros",
  },
  "captions.copyLog": {
    eng: "Copy log",
    ru: "Скопировать лог",
    pt: "Copiar log",
  },
  "captions.closeLog": {
    eng: "Close",
    ru: "Закрыть",
    pt: "Fechar",
  },
  "captions.imageDownload": {
    eng: "Close",
    ru: "Закрыть",
    pt: "Fechar",
  },
  "captions.dndLabel": {
    eng: "Drop file here to upload",
    ru: "Перетащите файл сюда",
    pt: "Solte o arquivo aquivo para ser carregado",
  },
  name: {
    eng: "Test widget",
    ru: "Тестовый виджет",
    pt: "Widget de teste",
  },
  "New message event": {
    eng: "New message!",
    ru: "Новое сообщение!",
    pt: "Nova mensagem!",
  },
  "Analytics:ClientDialog:Connecting_to_a_chat_with_an_operator": {
    eng: "Connecting to the chat with an agent",
    ru: "Подключение к диалогу с оператором",
    pt: "Conectando o chat ao agente",
  },
  "Analytics:ClientDialog:Operator_is_connected": {
    eng: "$[1] has joined the chat",
    ru: "Оператор $[1] подключился",
    pt: "$[1] entrou no chat",
  },
  "Analytics:ClientDialog:The_operator_ended_the_dialogue": {
    eng: "The agent has left the chat",
    ru: "Оператор вышел из диалога",
    pt: "O agente saiu do chat",
  },
  "Analytics:ClientDialog:The_client_has_completed_the_dialogue": {
    eng: "The customer has closed the chat",
    ru: "Клиент завершил диалог",
    pt: "O cliente fechou o chat",
  },
  "Analytics:ClientDialog:Chat_is_transferred_to": {
    eng: "The chat is assigned to the group: $[1]",
    ru: "Диалог переведен на группу: $[1]",
    pt: "O chat é atribuído ao grupo: $[1]",
  },
  "Analytics:ClientDialog:Chat_with_the_operator_is_complete": {
    eng: "The chat with the agent has finished",
    ru: "Диалог с оператором завершен",
    pt: "O chat com o agente foi finalizado",
  },
  "Analytics:ClientDialog:Switching_to_another_operator": {
    eng: "Switching to another agent",
    ru: "Переключаем на другого оператора",
    pt: "Mudar para outro agente",
  },
  "Analytics:ClientDialog:The_client_is_blocked_in_operator_chat": {
    eng: "The customer is blocked by the agent",
    ru: "Клиент заблокирован оператором",
    pt: "O cliente está bloqueado pelo agente",
  },
  "Analytics:ClientDialog:The_client_is_unblocked_in_operator_chat": {
    eng: "The customer is unblocked by the agent",
    ru: "Клиент разблокирован оператором",
    pt: "O cliente está desbloqueado pelo agente",
  },
  "Start new test": {
    eng: "Restart test",
    ru: "Начать тест заново",
    pt: "Reiniciar teste",
  },
  "Start new session": {
    eng: "Start new session",
    ru: "Новая сессия",
    pt: "Iniciar nova sessão",
  },
  "Session has been restarted": {
    eng: "Session has been restarted",
    ru: "Перезапуск сессии",
    pt: "A sessão foi reiniciada",
  },
  "Button:StopTest": {
    eng: "Stop testing",
    ru: "Остановить тестирование",
    pt: "Parar de testar",
  },
  "Videourl:NotValid": {
    ru: "Некорректная ссылка на видео",
    eng: "Incorrect video link",
  },
  "Imageurl:NotValid": {
    ru: "Некорректная ссылка на изображение",
    eng: "Incorrect image link",
  },
  "JustWidget:Modal:CancelButton": {
    ru: "Отмена",
    eng: "Cancel",
  },
  "JustWidget:Modal:CloseButton": {
    ru: "Закрыть",
    eng: "Close",
  },
  "RecordVoice:Modal:Title": {
    ru: "Закрыть чат?",
    eng: "Close chat?",
  },
  "RecordVoice:Modal:Description": {
    ru: "Неотправленные сообщения будут удалены",
    eng: "Unsent messages will be deleted",
  },
  "Form:Modal:AccessMicrophone:Title": {
    ru: "Разрешите доступ к микрофону",
    eng: "Allow microphone access",
  },
  "Form:Modal:AccessMicrophone:Description:OnePart": {
    ru: "Нажмите",
    eng: "Click",
  },
  "Form:Modal:AccessMicrophone:Description:SecondPart": {
    ru: "слева от адресной строки и разрешите доступ",
    eng: "to the left of the address bar and allow access",
  },
};
