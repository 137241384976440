import React, { PureComponent } from "react";
import classNames from "classnames";

import FileIcon from "../icons/FileIcon";
import DnDArea from "./DnDArea";
import Buttons from "./Buttons";
import FileInfo from "./FileInfo";
import localization from "../../../../localization";
import ActionButton from "./ActionButton/ActionButton";
import Modal from "../Modal/Modal";
import { ModalHeader, ModalBody, ModalButtons } from "../Modal/ModalComponents";
import Setting from "../icons/Setting";
import RecordVoice from "./RecordVoice/RecordVoice";
const t = localization.translate;

export default class Form extends PureComponent {
  textInput = null;
  fileInput = null;
  textareaRef = React.createRef();

  state = {
    loadProgress: 0,
    fileResponse: null,
    file: null,
    isVoiceRecord: false,
    microphonePermission: null,
    isModalOpen: false,
  };

  checkFormHasValues = () =>
    Boolean(this.textInput?.value?.trim() || this.state.fileResponse);

  setTextRef = (t) => {
    this.textInput = t;
    this.props.setTextInput(t);
    this.textareaRef.current = t;
  };

  setFileRef = (f) => {
    this.fileInput = f;
    this.props.setImageInput(f);
  };

  clearFile = () => {
    this.setState({ loaded: 0, fileResponse: null, file: null });
    if (this.fileInput) {
      this.fileInput.value = "";
    }
    this.props.actions.clearFile();
  };

  onUploadProgress = ({ loaded, total }) =>
    this.setState({ loadProgress: Math.round((loaded * 100) / total) });

  uploadFile = async () => {
    const fileResponsePromise = this.props.actions.sendFileToStorage(
      this.onUploadProgress
    );
    this.setState({ file: this.fileInput.files[0] });
    const fileResponse = await fileResponsePromise;
    this.setState({ fileResponse });
  };

  calcHeightTextArea = () => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = Math.min(textarea.scrollHeight, 70) + "px";
  };

  onSendButton = async (e) => {
    if (e?.type === "submit") {
      e.preventDefault();
      e.stopPropagation();
    }
    const {
      actions: { onAnswerButtonClick },
    } = this.props;

    try {
      await onAnswerButtonClick(null);
    } catch (e) {
      console.error(e);
    } finally {
      this.clearFile();
      this.calcHeightTextArea();
    }
  };

  stopVoiceRecord = () => {
    this.setState({ isVoiceRecord: false });
  };

  onVoiceButton = async () => {
    const { state } = await navigator.permissions
      .query({ name: "microphone" })
      .catch((error) => {
        console.log(
          "An error occurred while obtaining authorisation to use the microphone",
          error
        );
      });

    this.setState({ microphonePermission: state });

    if (state === "granted") this.setState({ isVoiceRecord: true });
    if (state === "denied") this.setState({ isModalOpen: true });
    if (state === "prompt") {
      this.setState({ isModalOpen: true });

      await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => stream.getTracks().forEach((track) => track.stop()))
        .catch((error) =>
          console.log(
            "An error occurred when disconnecting the microphone",
            error
          )
        )
        .finally(() => this.setState({ isModalOpen: false }));
    }
  };

  onButtonCancel = () => {
    this.setState({ isModalOpen: false });
  };

  onChange = () => this.forceUpdate();

  onInput = () => this.calcHeightTextArea();

  onPaste = async (e) => {
    const data = (e.clipboardData || e.originalEvent.clipboardData).items?.[0];
    if (!data || (data.type === "text/plain" && data.kind === "string")) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.file = data.getAsFile() || null;

    if (this.file) {
      const fileList = new DataTransfer();
      fileList.items.add(this.file);
      this.fileInput.files = fileList.files;
      this.uploadFile();
    }
  };

  render() {
    const {
      data,
      actions,
      blockForm,
      sendBtn,
      voiceMessages,
      fileUpload,
      setActionsWrapper,
      wrapperRef,
      buttons,
      buttonClick,
      placeholder,
      toggleWidget,
      audioMessageLimits,
    } = this.props;

    const { captions, forcedLanguage } = data;
    const {
      loadProgress,
      file,
      isVoiceRecord,
      microphonePermission,
      isModalOpen,
    } = this.state;
    const confirmIsDisabled = this.checkFormHasValues() === false;
    const disabledForm =
      blockForm && Boolean(buttons?.find((x) => typeof x === "string"));

    return (
      <>
        <Buttons
          testMode={this.props.testMode}
          toggleWidget={toggleWidget}
          options={data}
          buttons={buttons}
          classname="justwidget--buttons-top"
          buttonClick={buttonClick}
        />
        <div className="justwidget--container-form">
          <form
            className={classNames(
              "justwidget--form",
              disabledForm && "justwidget--form_disabeld"
            )}
            onSubmit={this.onSendButton}
          >
            <div
              className="justwidget--actions"
              ref={(a) => setActionsWrapper(a)}
            >
              {isVoiceRecord ? (
                <RecordVoice
                  uploadVoice={actions.uploadVoice}
                  stopVoiceRecord={this.stopVoiceRecord}
                  audioMessageLimits={audioMessageLimits}
                />
              ) : (
                <>
                  {fileUpload && (
                    <div
                      className={classNames("justwidget--image-upload", {
                        "justwidget--hidden": file,
                      })}
                    >
                      <label>
                        <FileIcon />
                        <input
                          name="image"
                          type="file"
                          onChange={this.uploadFile}
                          ref={this.setFileRef}
                        />
                      </label>
                    </div>
                  )}
                  <textarea
                    rows="1"
                    maxLength={data.inputMaxLen}
                    className={"justwidget--text"}
                    data-test-id="Justwidget.TextArea"
                    onChange={this.onChange}
                    onInput={this.onInput}
                    placeholder={placeholder}
                    ref={this.setTextRef}
                    disabled={disabledForm}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 || e.key === "Enter") {
                        e.preventDefault();
                        this.onSendButton();
                      } else {
                        actions.onClearText(e);
                      }
                    }}
                    onPaste={this.onPaste}
                  />

                  {voiceMessages &&
                    confirmIsDisabled &&
                    (microphonePermission === "denied" ? (
                      <ActionButton
                        buttonType="voiceDenied"
                        type="button"
                        onClick={this.onVoiceButton}
                      />
                    ) : (
                      <ActionButton
                        buttonType="voice"
                        type="button"
                        onClick={this.onVoiceButton}
                      />
                    ))}

                  {sendBtn && (!voiceMessages || !confirmIsDisabled) && (
                    <ActionButton
                      buttonType="send"
                      disabled={confirmIsDisabled}
                      type="submit"
                    />
                  )}
                </>
              )}
            </div>
          </form>
          {file && (
            <FileInfo
              clearFile={this.clearFile}
              loadProgress={loadProgress}
              file={file}
            />
          )}
        </div>
        {fileUpload && !file && (
          <DnDArea
            fileRef={this.fileInput}
            label={forcedLanguage ? t("captions.dndLabel") : captions.dndLabel}
            uploadFile={this.uploadFile}
            wrapperRef={wrapperRef}
          />
        )}
        <Buttons
          buttons={buttons}
          classname="justwidget--buttons-bottom"
          buttonClick={buttonClick}
        />
        {isModalOpen && (
          <Modal>
            <ModalHeader>{t("Form:Modal:AccessMicrophone:Title")}</ModalHeader>
            <ModalBody>
              {t("Form:Modal:AccessMicrophone:Description:OnePart")}
              <span>
                <Setting />
              </span>
              {t("Form:Modal:AccessMicrophone:Description:SecondPart")}
            </ModalBody>
            <ModalButtons>
              <button
                onClick={this.onButtonCancel}
                className="justwidget--modal--button-cancel"
              >
                {t("JustWidget:Modal:CancelButton")}
              </button>
            </ModalButtons>
          </Modal>
        )}
      </>
    );
  }
}
