import React from "react";

const VoiceMessages = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11C9.65688 11 11 9.65687 11 8V3C11 1.34312 9.65688 0 8 0C6.34312 0 5 1.34312 5 3V8C5 9.65687 6.34312 11 8 11ZM6 3C6 1.89719 6.89719 1 8 1C9.10281 1 10 1.89719 10 3V8C10 9.10281 9.10281 10 8 10C6.89719 10 6 9.10281 6 8V3ZM12.75 6H12.25C12.1119 6 12 6.11188 12 6.25V8C12 10.2956 10.0563 12.1462 7.72719 11.9909C5.60313 11.8494 4 9.98781 4 7.85906V6.25C4 6.11188 3.88813 6 3.75 6H3.25C3.11187 6 3 6.11188 3 6.25V7.82312C3 10.4291 4.92656 12.7019 7.5 12.9616V15H5.25C5.11188 15 5 15.1119 5 15.25V15.75C5 15.8881 5.11188 16 5.25 16H10.75C10.8881 16 11 15.8881 11 15.75V15.25C11 15.1119 10.8881 15 10.75 15H8.5V12.9684C11.0222 12.7156 13 10.5875 13 8V6.25C13 6.11188 12.8881 6 12.75 6Z"
      fill="#333C45"
    />
  </svg>
);

export default VoiceMessages;
