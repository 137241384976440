import React from 'react';

export const closeIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.48736 8.00029L12.8536 4.63404C13.0483 4.43935 13.0483 4.12342 12.8536 3.92842L12.0714 3.14623C11.8767 2.95154 11.5608 2.95154 11.3658 3.14623L7.99986 6.51279L4.63361 3.14654C4.43892 2.95185 4.12298 2.95185 3.92798 3.14654L3.14611 3.92842C2.95142 4.1231 2.95142 4.43904 3.14611 4.63404L6.51236 8.00029L3.14611 11.3665C2.95142 11.5612 2.95142 11.8772 3.14611 12.0722L3.92829 12.8544C4.12298 13.049 4.43892 13.049 4.63392 12.8544L7.99986 9.48779L11.3661 12.854C11.5608 13.0487 11.8767 13.0487 12.0717 12.854L12.8539 12.0719C13.0486 11.8772 13.0486 11.5612 12.8539 11.3662L9.48736 8.00029Z"/>
</svg>

export const StopTestIcon = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8125 6C11.8125 2.78906 9.21094 0.1875 6 0.1875C2.78906 0.1875 0.1875 2.78906 0.1875 6C0.1875 9.21094 2.78906 11.8125 6 11.8125C9.21094 11.8125 11.8125 9.21094 11.8125 6ZM1.3125 6C1.3125 3.41016 3.41016 1.3125 6 1.3125C8.58984 1.3125 10.6875 3.41016 10.6875 6C10.6875 8.58984 8.58984 10.6875 6 10.6875C3.41016 10.6875 1.3125 8.58984 1.3125 6ZM8.25 4.125V7.875C8.25 8.08125 8.08125 8.25 7.875 8.25H4.125C3.91875 8.25 3.75 8.08125 3.75 7.875V4.125C3.75 3.91875 3.91875 3.75 4.125 3.75H7.875C8.08125 3.75 8.25 3.91875 8.25 4.125Z" fill="#333C45"/>
</svg>

