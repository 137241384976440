import React from "react";

const VoiceMessagesWarning = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <rect width="16" height="16" rx="8" fill="#E6C323" />
    <path
      d="M8.74707 4.40625L8.58594 9.34277H7.41406L7.24805 4.40625H8.74707ZM7.20898 10.8711C7.20898 10.6628 7.2806 10.4902 7.42383 10.3535C7.57031 10.2135 7.76237 10.1436 8 10.1436C8.24089 10.1436 8.43294 10.2135 8.57617 10.3535C8.7194 10.4902 8.79102 10.6628 8.79102 10.8711C8.79102 11.0729 8.7194 11.2438 8.57617 11.3838C8.43294 11.5238 8.24089 11.5938 8 11.5938C7.76237 11.5938 7.57031 11.5238 7.42383 11.3838C7.2806 11.2438 7.20898 11.0729 7.20898 10.8711Z"
      fill="#333C45"
    />
  </svg>
);

export default VoiceMessagesWarning;
