import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {closeIcon} from "../icons/common";
import axios from 'axios';


const Opengraph = ({url, onHide}) => {

    const [opengraph, setOpengraph] = useState(null);
    const cancelTokenSource = useRef(axios.CancelToken.source()).current;

    useMemo(() => {
        axios
            .get(`${window.JustWidgetBasePath}/restapi/opengraph?url=${url}`, {
                cancelToken: cancelTokenSource.token
            })
            .then((payload) => {
                const data = payload.data;
                if(data.title || data.description || data.image){
                    setOpengraph(payload.data);
                }
            }).catch(console.error)

    }, [url]);

    useEffect(() => {
        return () => cancelTokenSource.cancel();
    }, [url]);

    useEffect(() => {
        setTimeout(()=>{
            document.dispatchEvent(new Event('opengraphLoaded'));
        }, 500);
    }, [opengraph]);

    const openPage = useCallback(() => {
        window.open(url, '_blank');
    }, [url]);

    const prevent = useCallback((event) => {
        event.stopPropagation();
    }, []);

    if(!opengraph) return null;

    return <div className='opengraph'>
        <div className='hide_opengraph' >
            <div onClick={onHide}>
                {closeIcon}
            </div>
        </div>
        <div className='og_border'/>
        <div className='opengraph_content' onClick={openPage}>
            {opengraph.host && <div className="origin">
                <a href={url} target="_blank" rel='noopener noreferrer' onClick={prevent}>{opengraph.host}</a>
            </div>}
            {opengraph.title && <div className="title">
                {opengraph.title}
            </div>}
            {opengraph.description && <div className="description">
                {opengraph.description}
            </div>}
            {opengraph.image &&
            <div className='preview'>
                <img src={opengraph.image} alt={opengraph.title}/>
            </div>}
        </div>
    </div>
}

export default Opengraph;