import React from "react";
import ReactAudioPlayer from "react-audio-player";

const VoiceMessage = ({ url }) => (
  <div className="justwidget--voice-message">
    <ReactAudioPlayer
      src={url}
      controlsList="nodownload nofullscreen noremoteplayback"
      controls
    />
  </div>
);

export default VoiceMessage;
