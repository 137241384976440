import React from "react";

const UnreadMessagesCount = ({ counter }) => {
  if (!counter || counter === 0) return null;
  const displayCounter = counter > 9 ? "9+" : counter;

  return (
    <div className="justwidget--asst-unread_messages_count">
      {displayCounter}
    </div>
  );
};

export default UnreadMessagesCount;
