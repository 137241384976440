import React from "react";

const ModalHeader = ({ children }) => (
  <div className="justwidget--modal--header">{children}</div>
);

const ModalBody = ({ children }) => (
  <div className="justwidget--modal--body justwidget--modal--body--icon">
    {children}
  </div>
);

const ModalButtons = ({ children }) => (
  <div className="justwidget--modal--buttons">{children}</div>
);

export { ModalHeader, ModalBody, ModalButtons };
