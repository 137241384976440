import React, { PureComponent } from 'react';
import LoadHistoryIcon from '../icons/LoadHistoryIcon';
import Message from './Message';
import ScrollRowIcon from '../icons/ScrollRowIcon';
import localization from '../../../../localization';
const t = localization.translate;

export default class Messages extends PureComponent {
  state = { showHistoryIcon: true, hideTop: false, hideBottom: false };

  messagesRef = null;

  topDefferedTimer = null;
  bottomDefferedTimer = null;
  scrollTimer = null;
  temporaryScrollValue = null;
  scrollValue = null;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state?.isOpen && !prevProps.state?.isOpen) {
      this.scrollTimer = setTimeout(() => {
        this.messagesRef.scrollTo({
          top: this.scrollValue,
          behavior: 'smooth',
        });
      }, 200);
    }
    if (!this.props.state?.isOpen && prevProps.state?.isOpen) {
      this.scrollValue = this.temporaryScrollValue;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.topDefferedTimer);
    clearTimeout(this.bottomDefferedTimer);
    clearTimeout(this.scrollTimer);
  }

  onLoadHistory = () => {
    if (this.state.showHistoryIcon) this.setState({ showHistoryIcon: false });
    this.props.functions.loadHistory();
  };

  onScroll = () => {
    const {
      state,
      functions: { loadHistory },
    } = this.props;
    if (
      !this.state.showHistoryIcon &&
      this.messagesRef.scrollTop === 0 &&
      state.loadHistory
    ) {
      loadHistory();
    }

    const { scrollHeight, clientHeight, scrollTop } = this.messagesRef;
    const { hideTop, hideBottom } = this.state;

    if (this.props.state?.isOpen) {
      this.temporaryScrollValue = this.messagesRef.scrollTop;
    }

    if (hideTop !== (scrollTop === 0)) {
      clearTimeout(this.topDefferedTimer);
      this.setState({ hideTop: !hideTop });
      //without deffered timer arrow would blink after new message
      this.topDefferedTimer = setTimeout(
        () => this.setState({ hideTop: !hideTop }),
        300
      );
    } else {
      clearTimeout(this.topDefferedTimer);
    }
    if (hideBottom !== (scrollTop === scrollHeight - clientHeight)) {
      clearTimeout(this.bottomDefferedTimer);
      this.bottomDefferedTimer = setTimeout(
        () => this.setState({ hideBottom: !hideBottom }),
        300
      );
    } else {
      clearTimeout(this.bottomDefferedTimer);
    }
  };

  toScrollUp = () => {
    this.messagesRef.scrollTo({ top: 0, behavior: 'smooth' });
  };

  toScrollDown = () => {
    const { scrollHeight, clientHeight } = this.messagesRef;
    this.messagesRef.scrollTo({
      top: scrollHeight - clientHeight,
      behavior: 'smooth',
    });
  };

  render() {
    const {
      getDomFunctions,
      functions,
      data: { options },
      state,
    } = this.props;
    const { loadHistory, messages, showSpinner } = state;
    const { showHistoryIcon, hideTop, hideBottom } = this.state;

    const { captions, forcedLanguage } = options;

    let showScrollButtons = false;
    if (this.messagesRef) {
      const { scrollHeight, clientHeight } = this.messagesRef;
      showScrollButtons = scrollHeight > clientHeight;
    }
    return (
      <div className="justwidget--messages-container">
        <div
          className="justwidget--messages"
          onScroll={this.onScroll}
          ref={(mes) => {
            getDomFunctions.setMessagesWrapper(mes);
            this.messagesRef = mes;
          }}
        >
          {loadHistory > 0 && showHistoryIcon && (
            <span className="justwidget--history" onClick={this.onLoadHistory}>
              <LoadHistoryIcon />
              {forcedLanguage
                ? t('captions.loadHistory')
                : captions.loadHistory}
            </span>
          )}
          {messages.map((message) => {
            const { textRequest, timestamp, text } = message;
            let key =
              textRequest?.timestamp ||
              textRequest?.widgetMessageTimestamp ||
              text?.timestamp ||
              timestamp;
            return (
              <Message
                options={options}
                message={message}
                showImageOverlay={functions.showImageOverlay}
                showErrorOverlay={functions.showErrorOverlay}
                buttonClick={functions.buttonClick}
                sendText={functions.sendText}
                propsTimestamp={key}
                key={`message_${key}`}
              />
            );
          })}

          {showSpinner && (
            <Message
              options={options}
              message={{ text: {} }}
              showImageOverlay={functions.showImageOverlay}
              showErrorOverlay={functions.showErrorOverlay}
              buttonClick={functions.buttonClick}
              sendText={functions.sendText}
              key="message_spinner"
              isSpinner
            />
          )}
          {/*<Carousel/>*/}
        </div>
        {showScrollButtons && !hideTop && (
          <button
            onClick={this.toScrollUp}
            className="justwidget--scroll-button"
          >
            <ScrollRowIcon />
          </button>
        )}
        {showScrollButtons && !hideBottom && (
          <button
            onClick={this.toScrollDown}
            className="justwidget--scroll-button justwidget--scroll-button-bottom"
          >
            <ScrollRowIcon />
          </button>
        )}
      </div>
    );
  }
}
