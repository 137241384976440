import React from "react";
import DefaultLogo from "../icons/DefaultLogo";
import Bubble from "./Bubble";
import { CloseIconButton } from "../CloseIconButton";
import UnreadMessagesCount from "../UnreadMessagesCount";

export default function index({
  logo,
  toggleWidget,
  showBubble,
  data,
  closeControls,
  showCloseButton,
  showUnreadMessagesCount,
  unreadMessagesCount,
}) {
  if (!closeControls.show) return null;

  return (
    <div
      className={`justwidget--asst justwidget--toggle-action`}
      onClick={toggleWidget}
    >
      <div className="justwidget--asst-pic-waves" />
      {showCloseButton && <CloseIconButton />}
      {showUnreadMessagesCount && (
        <UnreadMessagesCount counter={unreadMessagesCount} />
      )}
      {!!logo ? (
        <div
          className="justwidget--asst-pic"
          style={{ backgroundImage: `url(${logo})` }}
        />
      ) : (
        <DefaultLogo />
      )}
      {showBubble && <Bubble data={{ ...data }} />}
    </div>
  );
}
