import React, { useEffect, useState } from "react";
import RecordTimer from "./RecordTimer";
import { useAudioRecorder } from "./useRecordVoice";
import ActionButton from "../ActionButton/ActionButton";
import {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from "../../Modal/ModalComponents";
import Modal from "../../Modal/Modal";
import RoundSpinner from "../../RoundSpinner/RoundSpinner";
import { t } from "../../../../../localization";
import { useToggle } from "../../../../../hooks";

const RecordVoice = ({ uploadVoice, stopVoiceRecord, audioMessageLimits }) => {
  const [isOpenModal, , , toggleModal] = useToggle(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    sendVoiceMessage,
    abortRecording,
    audioFile,
    statusRef,
    secondsRecording,
    isMaxSecondsLimit,
  } = useAudioRecorder(audioMessageLimits?.fileSizeLimitInSec);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Enter") sendVoiceMessage();
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [sendVoiceMessage]);

  useEffect(() => {
    if (audioFile) {
      setIsLoading(true);
      uploadVoice(audioFile)
        .catch((error) => {
          console.log(
            "An error occurred while downloading a voice message ",
            error
          );
        })
        .finally(() => {
          setIsLoading(false);
          stopVoiceRecord();
        });
    }
  }, [audioFile, statusRef, stopVoiceRecord, uploadVoice]);

  const onAbortRecording = () => {
    abortRecording();
    stopVoiceRecord();
  };

  return (
    <>
      <RecordTimer
        seconds={secondsRecording}
        isStopRecIcon={isMaxSecondsLimit}
      />
      <ActionButton
        buttonType="delete"
        type="button"
        onClick={() => toggleModal(true)}
        disabled={isLoading}
      />
      {isLoading ? (
        <div className="justwidget--record--wrapper_spinner">
          <div>
            <RoundSpinner />
          </div>
        </div>
      ) : (
        <ActionButton
          buttonType="send"
          type="button"
          onClick={sendVoiceMessage}
        />
      )}

      {isOpenModal && (
        <Modal>
          <ModalHeader>{t("RecordVoice:Modal:Title")}</ModalHeader>
          <ModalBody>{t("RecordVoice:Modal:Description")}</ModalBody>
          <ModalButtons>
            <button
              className="justwidget--modal--button-close"
              onClick={onAbortRecording}
            >
              {t("JustWidget:Modal:CloseButton")}
            </button>
            <button
              onClick={() => toggleModal(false)}
              className="justwidget--modal--button-cancel"
            >
              {t("JustWidget:Modal:CancelButton")}
            </button>
          </ModalButtons>
        </Modal>
      )}
    </>
  );
};

export default RecordVoice;
