import React from "react";
import SendIcon from "../../icons/SendIcon";
import VoiceMessages from "../../icons/VoiceMessages";
import Delete from "../../icons/Delete";
import VoiceMessagesDenied from "../../icons/VoiceMessagesDenied";
import VoiceMessagesWarning from "../../icons/VoiceMessagesWarning";

const typeMap = {
  send: { icon: <SendIcon />, dataTestId: "SendButton" },
  voice: { icon: <VoiceMessages />, dataTestId: "VoiceButton" },
  voiceDenied: { icon: <VoiceMessagesDenied />, dataTestId: "VoiceButton" },
  delete: { icon: <Delete />, dataTestId: "DeleteButton" },
};

const ActionButton = (props) => {
  const { icon, dataTestId } = typeMap[props.buttonType];

  return (
    <div className="justwidget--action__button">
      <button
        {...props}
        className={`justwidget--${props.buttonType}`}
        data-test-id={`Justwidget.${dataTestId}`}
      >
        {props.buttonType === "voiceDenied" && (
          <div className="justwidget--warning-icon">
            <VoiceMessagesWarning />
          </div>
        )}
        {icon}
      </button>
    </div>
  );
};

export default ActionButton;
