import React from 'react';
function PoweredLabel({label, link}) {
    return <div className="justwidget--powered_label">
        <a href={link || "https://aimylogic.com/ru/try-chat-widget-by-aimylogic"}
           rel="noopener noreferrer"
           target="_blank">
            <span>Powered by</span>
            {!label && <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.21989 12.0283L6.99878 13.311C6.79429 13.4333 6.56075 13.4986 6.32248 13.5C6.08421 13.5014 5.84992 13.4389 5.64399 13.319L3.41197 12.0283L1.26148 10.7907C1.03341 10.6815 0.840885 10.51 0.706141 10.2961C0.571396 10.0821 0.499931 9.83437 0.5 9.58151V4.41932C0.500022 4.18478 0.561626 3.95436 0.67865 3.7511C0.795675 3.54784 0.964018 3.37887 1.16684 3.26109L3.40687 1.97036L5.62725 0.688363C5.83135 0.566463 6.06437 0.501432 6.3021 0.500023C6.53982 0.498615 6.7736 0.560881 6.97913 0.680355L9.21479 1.97036L11.3653 3.21158C11.5916 3.3214 11.7826 3.49269 11.9162 3.70587C12.0498 3.91906 12.1208 4.16554 12.1209 4.41714V9.58006C12.1209 9.8145 12.0593 10.0448 11.9423 10.248C11.8253 10.4511 11.6569 10.62 11.4541 10.7376L9.21989 12.0283Z" fill="#1B7ABE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.07868 5.60443L7.0716 2.12827C6.99687 1.99503 6.8882 1.88395 6.75664 1.8063C6.62508 1.72866 6.47531 1.68721 6.32255 1.68618C6.16979 1.68514 6.01947 1.72455 5.88687 1.80041C5.75427 1.87627 5.64411 1.98587 5.56757 2.11807L3.55759 5.60443L1.58182 9.02235C1.47684 9.16924 1.42103 9.34558 1.42239 9.52612C1.42277 9.75614 1.51432 9.97664 1.67698 10.1393C1.83963 10.3019 2.06013 10.3935 2.29015 10.3939H10.3403V10.3895C10.4917 10.3894 10.6405 10.3495 10.7717 10.2738C10.9029 10.1981 11.0119 10.0892 11.0878 9.95818C11.1637 9.82713 11.2039 9.67843 11.2043 9.52697C11.2047 9.37551 11.1653 9.22661 11.0901 9.09515L9.10343 5.65175C9.09542 5.635 9.09032 5.61899 9.07868 5.60443Z" fill="#93D5F6"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.39971 10.3917C8.57351 11.1943 7.46704 11.6432 6.31521 11.6432C5.16339 11.6432 4.05692 11.1943 3.23071 10.3917H9.39971Z" fill="#1CBBEE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M3.55744 5.60668L2.01482 8.27477C1.92747 7.92723 1.88321 7.57025 1.88306 7.2119C1.88338 6.25123 2.19599 5.31671 2.77375 4.5492C3.35152 3.78169 4.16312 3.22281 5.08622 2.95679L3.55744 5.60449V5.60668Z" fill="#1CBBEE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.619 8.2704L9.10627 5.64963L9.08152 5.60231L7.55273 2.95679C8.47374 3.22489 9.28286 3.78465 9.85851 4.55196C10.4342 5.31926 10.7452 6.25267 10.745 7.2119C10.7453 7.56921 10.7031 7.92529 10.619 8.27258V8.2704Z" fill="#1CBBEE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.10334 5.65187L10.6161 8.27264C10.4175 9.0797 9.99512 9.81428 9.39745 10.3918H3.22844C2.63301 9.81621 2.21168 9.08457 2.0127 8.28065L3.55749 5.60674L5.08628 2.95903C5.89305 2.72704 6.74886 2.72704 7.55563 2.95903L9.08441 5.60455L9.10916 5.65187H9.10334Z" fill="#FEFEFE"/>
            </svg>}
            <span style={label ? {paddingLeft: '3px'} : {}}>{label || 'Just AI'}</span>
        </a>
    </div>;
}

export default React.memo(PoweredLabel);