import React, {PureComponent} from 'react';

const VALUES = {
    avatarSize: {
        small: 24,
        medium: 32,
        big: 40,
        default: 40
    },
    mainFontSize: {
        small: 14,
        medium: 18,
        big: 20,
        default: 14
    },
    helpedFontSize: {
        small: 12,
        medium: 14,
        big: 16,
        default: 12
    },
    linkIconSize: {
        small: 10.5,
        medium: 12,
        big: 13.5,
        default: 10.5
    }
};

export default class Style extends PureComponent {
    getValue = (key, value) => {
        return VALUES[key][value] || VALUES[key].default
    };

    render() {
        const {palette, sizes, font} = this.props;
        return <style type="text/css">
            {`.justwidget {
            --main-background-color: ${hexToRgba(palette.chatBackgroundColor, palette.chatBackgroundOpacity)};
  --header-background-color: ${palette.headlineBackgroundColor};
  --operator-background-color: ${palette.botMessageBackgroundColor};
  --user-background-color: ${palette.userMessageBackgroundColor};
  
  --scroll-color: #BFBEBD;

  --headline-font-color: ${palette.headlineTextColor === 'dark' ? '#2A2722' : '#fff'};
  --user-font-color: ${palette.userMessageTextColor === 'dark' ? '#000' : '#fff'};
  --bot-font-color: ${palette.botMessageTextColor === 'dark' ? '#000' : '#fff'};
  --form-buttons-color: ${palette.formButtonsColor};
  --form-text-color: ${palette.formTextColor};

  --font: ${font}, sans-serif;

  --main-bright-color: ${palette.headlineBackgroundColor};
  --main-bright-color-rgb: 74, 144, 226;

  --main-bright-color-disabled: #333C45;
  --main-bright-color-disabled-background: rgba(191,190, 189, 0.3);
  --send-file-background: rgba(228, 229, 230, 0.3);
  --send-progress-background: rgba(74, 144, 226, 0.3);

  --avatar-width: ${this.getValue('avatarSize', sizes.avatar)}px;
  --main-font-size: ${this.getValue('mainFontSize', sizes.font)}px;
  --username-font-size: ${this.getValue('helpedFontSize', sizes.font)}px;

  --button-size: 26px;
  --button-link-icon-size: ${this.getValue('linkIconSize', sizes.avatar)}px;
  }`}
        </style>;
    };

}

function hexToRgba(hex, alpha) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return 'transparent';
    return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha / 100})`;
}

