import React, {PureComponent} from 'react';
import classNames from 'classnames';

import RestartIcon, {SoundOnIcon, SoundOffIcon} from "../icons/RestartIcon";
import RoundedImage from "../RoundedImage";
import CloseIcon from "../icons/CloseIcon";
import {RestartSessionIcon} from "../icons/RestartSessionIcon";
import localization from "../../../../localization";
const t = localization.translate;

export default class Header extends PureComponent {
    startTrackMouse = e => {
        this.positionY = e.pageY;
        document.addEventListener('mousemove', this.recalculateHeight);
        document.addEventListener('mouseup', () => document.removeEventListener('mousemove', this.recalculateHeight))
    };

    recalculateHeight = e => {
        const newPositionY = e.pageY;
        this.props.setMaxHeight(newPositionY - this.positionY);
        this.positionY = newPositionY;
    };

    render() {
        const {headline, sendStart, testMode, logo, soundControl, closeControls, sendNewSession} = this.props;

        const {show} = headline;

        return <div
            onDragOver={(e) => e.stopPropagation()}
            className={
                classNames("justwidget--headline",
                {"justwidget--headline-without-text": !headline.text})
            }>
            {show && headline.showLogo && logo && <RoundedImage url={logo}/>}
            {<div className="justwidget--headline-height-button-container" onMouseDown={this.startTrackMouse}>
                <div className="justwidget--headline-height-button"/>
            </div>}
            {show && headline.text && <h2>{headline.text}</h2>}
            <div className={'justwidget--headline--buttons'}>
                {soundControl && soundControl.soundVisible && <button
                    className="justwidget--sendStart"
                    onClick={soundControl.toggleSound}
                    data-test-id="Justwidget.OnOffSoundNotifications"
                >
                    {soundControl.soundOff
                        ? <SoundOffIcon/>
                        : <SoundOnIcon/>
                    }
                </button>
                }
                {testMode && <button
                    className="justwidget--sendStart"
                    onClick={sendNewSession}
                    data-test-id="Justwidget.NewSessionButton"
                    title={t('Start new session')}>
                    <RestartSessionIcon/>
                </button>}
                {testMode && <button
                    className="justwidget--sendStart"
                    onClick={sendStart}
                    data-test-id="Justwidget.SendStartButton"
                    title={t('Start new test')}>
                    <RestartIcon/>
                </button>}
                {!testMode && closeControls.options.show
                && <span
                    className={classNames("justwidget--close", "justwidget--toggle-action", {"justwidget--close-dark": !closeControls.show})}
                    onClick={closeControls.toggle}>
                        <CloseIcon/>
                    </span>
                }
            </div>

        </div>
    }
}