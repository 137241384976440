import React from 'react';
import ReactDOM from 'react-dom';
import DownloadIcon from "../icons/DownloadIcon";

export default function ImageOverlay({src, hideImageOverlay, label}) {
    let container = document.getElementById('JustWidgetImageOverlay');
    if (!container) {
        container = document.createElement('div');
        container.id = 'JustWidgetImageOverlay';
        container = document.body.appendChild(container);
    }

    const preventDefault = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return ReactDOM.createPortal(
        <div className="justwidget--image-overlay" onClick={hideImageOverlay}>
            <div className="justwidget--image-overlay-content">
                <div className="justwidget--image-overlay-image" onClick={preventDefault}>

                    <img alt='' src={src}/>
                </div>
            </div>
            <span className="justwidget--image-overlay--close" onClick={hideImageOverlay}>
                <svg width="32" height="32">
                    <path
                        d="M24 9.622L17.622 16 24 22.378 22.378 24 16 17.622 9.622 24 8 22.378 14.378 16 8 9.622 9.622 8 16 14.378 22.378 8z"
                        fill="#FFF"
                        fillRule="evenodd"/>
                </svg>
            </span>
            <a className="justwidget--image-overlay--download" href={src} download target="_blank">
                <DownloadIcon/>
                <span>{label}</span>
            </a>
        </div>
        , container)

};

