import {Component} from 'react';
import PropTypes from 'prop-types';

class VoiceController extends Component {

    recognition=null;
    voiceQueue=[];

    state={
        voiceGeneration: false,
        voiceRecognizing: false,
        voiceTestStartButton: true,
        voiceTestListenButton: false,
        voiceTestNotSupported: false,
    };

    componentDidMount () {
        this.initVoiceTest();
    }

    componentDidUpdate () {
        if(this.props.getVoiceInstance){
            this.props.getVoiceInstance(this)
        }
    }

    initVoiceTest= () => {
        if (Boolean(this.props.attributes.options.voiceTest) && this.props.attributes.options.voiceTest.show) {
            if (!('webkitSpeechRecognition' in window)) {
                this.setState({
                    voiceTestStartButton: false,
                    voiceTestNotSupported: true,
                })
            } else {
                this.recognition = new window.webkitSpeechRecognition();

                this.recognition.lang = this.props.attributes.options.language.toLowerCase();
                this.recognition.continuous = true;
                this.recognition.interimResults = false;

                this.recognition.onstart = ()=> {
                    this.playAudio(`${window.JustWidgetBasePath}/s/sounds/start.wav`);
                    this.setState({
                        voiceRecognizing: true,
                        voiceTestStartButton: false,
                        voiceTestListenButton: true,
                    });
                };

                this.recognition.onend = ()=> {
                    this.playAudio(`${window.JustWidgetBasePath}/s/sounds/finish.mp3`);
                    this.setState({
                        voiceRecognizing: false,
                        voiceTestStartButton: true,
                        voiceTestListenButton: false,
                    });
                };

                this.recognition.onerror = (event)=>{
                    this.setState({
                        voiceRecognizing: false,
                    });
                    this.playAudio(`${window.JustWidgetBasePath}/s/sounds/cancel.mp3`);
                    console.error("Can't recognize voice, reason:" + event.error);
                };

                const handleText = this.props.handleText;
                const sendData = this.props.sendData;

                this.recognition.onresult = function(event){
                    let resultPhrase = "";
                    for (let i = event.resultIndex; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            resultPhrase += event.results[i][0].transcript;
                        }
                    }
                    handleText(resultPhrase, true);
                    sendData({"textRequest": {"text": resultPhrase}});
                    this.stop();
                };
            }
        }
    };

    addTextInVoiceQueue= (url) => {
        let newQueue = [...this.voiceQueue];
        newQueue.push(url);
        this.voiceQueue = newQueue;
    };

    startRecognition= () => {
        if (Boolean(this.recognition) && !this.state.voiceRecognizing) {
            this.recognition.start();
        }
    };

    stopRecognition= () => {
        if (Boolean(this.recognition) && this.state.voiceRecognizing) {
            this.recognition.stop();
        }
    };

    abortRecognition= () => {
        if (Boolean(this.recognition) && this.state.voiceRecognizing) {
            this.recognition.abort();
        }
    };

    stopSpeech= () => {
        if (Boolean(this.voice)) {
            this.voice.pause();
        }
    };

    playVoice= url => {
        this.setState({
            voiceGeneration: true,
        });
        this.voice = new Audio(url);
        this.voice.addEventListener('playing', () => {
            this.stopRecognition();
            this.setState({
                voiceTestStartButton: false,
                voiceTestStopButton: true,
            });
        });
        this.voice.addEventListener('ended', () => {
            this.setState({
                voiceGeneration: false,
                voiceTestStartButton: true,
                voiceTestStopButton: false,
            });
            if (this.voiceQueue.length > 0) {
                this.playVoice(this.voiceQueue[0]);
                this.voiceQueue = this.voiceQueue.slice(1);
            } else {
                this.startRecognition();
            }
        });
        this.voice.addEventListener('pause', () => {
            this.setState({
                voiceGeneration: false,
                voiceTestStartButton: true,
                voiceTestStopButton: false,
            });
        });
        this.voice.addEventListener('error', () => {
            this.setState({
                voiceGeneration: false,
            });
            console.error("Error downloading media data");
        });
        this.voice.play();
    };

    playAudio= file => {
        const audio = new Audio(file);
        audio.play();
    };

    render() {
        const { children } = this.props;
        return children({
            state: {
                ...this.state,
            },
            addTextInVoiceQueue: this.addTextInVoiceQueue,
            startRecognition: this.startRecognition,
            stopRecognition: this.stopRecognition,
            abortRecognition: this.abortRecognition,
            stopSpeech: this.stopSpeech,
            playVoice: this.playVoice,
            playAudio: this.playAudio,
        });
    }
}

VoiceController.propTypes = {
    sendData: PropTypes.func.isRequired,
    handleText: PropTypes.func.isRequired,
};

export default VoiceController;
