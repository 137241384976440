import React, { useMemo } from 'react';

import FileInfoIcon from '../icons/FileInfoIcon';
import DeleteFileIcon from '../icons/DeleteFileIcon';
import { PreviewImage } from './PreviewImage';

export default function FileInfo({ file, loadProgress, clearFile }) {
  const { size } = file;
  const fileSize = useMemo(
    () =>
      size / (1024 * 1024)
        ? Math.round((size * 100) / (1024 * 1024)) / 100 + 'MB'
        : Math.round((size * 100) / 1024) / 100 + 'KB',
    [size]
  );
  const hasImage = file?.type.includes('image');
  return (
    <div className="justwidget--file-upload-preview">
      <div className="justwidget--file-upload-info">
        <div className="justwidget--file-upload-icon">
          {hasImage ? <PreviewImage file={file} /> : <FileInfoIcon />}
        </div>
        <div className="justwidget--file-name-and-progress">
          <span>{file.name}</span>
          {loadProgress < 100 ? (
            <div
              style={{
                background: `linear-gradient(90deg, var(--main-bright-color) 0%, var(--main-bright-color) ${loadProgress}%, var(--send-progress-background) ${loadProgress}%, var(--send-progress-background) 100%`,
              }}
              className="justwidget--file-progress"
            />
          ) : (
            <span>{fileSize}</span>
          )}
        </div>
      </div>
      <button onClick={clearFile}>
        <DeleteFileIcon />
      </button>
    </div>
  );
}
