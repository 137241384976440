import {useCallback, useState} from "react";

export const useToggle = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const SetTrueCallback = useCallback(() => {
        setValue(true);
    }, []);
    const SetFalseCallback = useCallback(() => {
        setValue(false);
    }, []);
    const ToggleCallback = useCallback(() => {
        setValue(value => !value);
    }, []);
    return [value, SetTrueCallback, SetFalseCallback, ToggleCallback];
};