import React from "react";

export default function RestartIcon() {
    return <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        color={'currentColor'}
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C8.775 12 11.1 10.05 12 7.5H10.5C9.6 9.225 7.95 10.5 6 10.5C3.525 10.5 1.5 8.475 1.5 6C1.5 3.525 3.525 1.5 6 1.5C7.275 1.5 8.325 2.025 9 3L6.75 5.25H12V0L10.5 1.5C9.15 0.75 7.65 0 6 0Z"/>
    </svg>
}

export function SoundOnIcon(){
    return <svg width="16" height="16" viewBox="0 0 16 16" color={'currentColor'} xmlns="http://www.w3.org/2000/svg">
        <path d="M7.21969 2.22018L4.43937 4.99987H1.25C0.835625 4.99987 0.5 5.33549 0.5 5.74987V10.2499C0.5 10.6639 0.835625 10.9999 1.25 10.9999H4.43937L7.21969 13.7796C7.68937 14.2492 8.5 13.9192 8.5 13.2492V2.7505C8.5 2.07987 7.68875 1.75112 7.21969 2.22018ZM15.5 7.99987C15.5 6.01456 14.4981 4.18925 12.8197 3.11737C12.47 2.89425 12.0062 2.998 11.7847 3.3505C11.5631 3.703 11.6666 4.16956 12.0162 4.393C13.2584 5.18643 14 6.53456 14 7.99987C14 9.46518 13.2584 10.8133 12.0162 11.6067C11.6666 11.8299 11.5631 12.2964 11.7847 12.6492C11.9881 12.973 12.4447 13.1224 12.8197 12.8824C14.4981 11.8105 15.5 9.98518 15.5 7.99987ZM11.0697 5.59768C10.7078 5.39987 10.2513 5.53018 10.0506 5.893C9.85094 6.25581 9.98313 6.71175 10.3459 6.91206C10.7494 7.13362 11 7.55081 11 7.99987C11 8.44925 10.7494 8.86612 10.3462 9.08768C9.98344 9.288 9.85125 9.74393 10.0509 10.1067C10.2519 10.4711 10.7087 10.6005 11.07 10.4021C11.9522 9.91612 12.5003 8.99581 12.5003 7.99956C12.5003 7.00331 11.9522 6.08331 11.0697 5.59768Z" />
    </svg>

}
export function SoundOffIcon(){
    return <svg width="16" height="16" viewBox="0 0 16 16" color={'currentColor'} xmlns="http://www.w3.org/2000/svg">
        <path d="M6.71969 2.22018L3.93937 4.99987H0.75C0.335625 4.99987 0 5.33549 0 5.74987V10.2499C0 10.6639 0.335625 10.9999 0.75 10.9999H3.93937L6.71969 13.7796C7.18937 14.2492 8 13.9192 8 13.2492V2.7505C8 2.07987 7.18875 1.75112 6.71969 2.22018ZM14.4263 7.99987L15.8525 6.57362C16.0494 6.37675 16.0494 6.05737 15.8525 5.86049L15.1394 5.14737C14.9425 4.9505 14.6231 4.9505 14.4263 5.14737L13 6.57362L11.5737 5.14737C11.3769 4.9505 11.0575 4.9505 10.8606 5.14737L10.1475 5.86049C9.95063 6.05737 9.95063 6.37675 10.1475 6.57362L11.5737 7.99987L10.1478 9.42581C9.95094 9.62268 9.95094 9.94206 10.1478 10.1389L10.8609 10.8521C11.0578 11.0489 11.3772 11.0489 11.5741 10.8521L13 9.42612L14.4263 10.8524C14.6231 11.0492 14.9425 11.0492 15.1394 10.8524L15.8525 10.1392C16.0494 9.94237 16.0494 9.623 15.8525 9.42612L14.4263 7.99987Z"/>
    </svg>
}