import React, {useCallback} from 'react';
import classNames from "classnames";
import LinkIcon from "../../icons/LinkIcon";

const DefaultImage = () => {
    return (
        <svg width="155" height="92" viewBox="0 0 155 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3738 26.7362C16.9503 25.5744 17.542 24.2927 18.6955 23.8735L79.2649 1.86131C80.4184 1.44208 81.6968 2.0441 82.1203 3.20592L104.742 65.2638C105.165 66.4256 104.573 67.7073 103.42 68.1265L42.8505 90.1387C41.697 90.558 40.4186 89.9559 39.9951 88.7941L17.3738 26.7362Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.5579 27.0128C15.9753 25.4144 16.7897 23.651 18.377 23.0741L78.9941 1.04459C80.5814 0.467721 82.3405 1.29585 82.9231 2.89426L105.557 64.9874C106.14 66.5858 105.326 68.3491 103.738 68.926L94.3321 72.3444C93.8992 72.5017 93.4195 72.2759 93.2606 71.8399C93.1017 71.404 93.3238 70.9231 93.7566 70.7658L103.163 67.3474C103.884 67.0852 104.254 66.2836 103.99 65.5571L81.3555 3.46398C81.0906 2.73744 80.291 2.36103 79.5695 2.62323L18.9525 24.6528C18.231 24.915 17.8608 25.7165 18.1256 26.4431L40.7598 88.5362C41.0246 89.2627 41.8242 89.6392 42.5457 89.3769L89.5762 72.2851C90.0091 72.1277 90.4889 72.3536 90.6478 72.7894C90.8067 73.2254 90.5845 73.7063 90.1516 73.8637L43.1211 90.9556C41.5338 91.5324 39.7747 90.7043 39.1921 89.1059L16.5579 27.0128Z" fill="#2EA9D6"/>
            <path d="M27.963 30.5114C27.5447 29.3637 28.1332 28.0962 29.2775 27.6804L76.9309 10.3621C78.0752 9.9462 79.3419 10.5395 79.7603 11.6871L94.1523 51.1692C94.5706 52.3169 93.9822 53.5843 92.8379 54.0001L45.1844 71.3184C44.0401 71.7343 42.7734 71.1411 42.355 69.9934L27.963 30.5114Z" fill="#C3E6F3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M29.5227 28.4433C28.8067 28.7036 28.4384 29.4967 28.7002 30.2149L43.1106 69.7474C43.3724 70.4657 44.1651 70.8369 44.8811 70.5766L49.5485 68.8804C49.9782 68.7243 50.4538 68.947 50.6109 69.378C50.768 69.8089 50.547 70.2847 50.1174 70.4409L45.4499 72.1371C43.8747 72.7096 42.1307 71.8929 41.5548 70.3128L27.1444 30.7803C26.5685 29.2003 27.3786 27.4553 28.9539 26.8828L52.8096 18.2132C53.2391 18.0571 53.7147 18.2798 53.8718 18.7107C54.0289 19.1416 53.808 19.6176 53.3784 19.7737L29.5227 28.4433ZM65.281 14.5644C65.124 14.1335 65.3449 13.6576 65.7745 13.5014L76.6652 9.54352C78.2405 8.97102 79.9844 9.78781 80.5603 11.3678L88.9032 34.2551C89.0602 34.686 88.8393 35.1619 88.4097 35.318C87.9801 35.4741 87.5045 35.2514 87.3474 34.8205L79.0045 11.9332C78.7427 11.2151 77.95 10.8438 77.234 11.104L66.3434 15.0619C65.9137 15.2181 65.4381 14.9953 65.281 14.5644ZM89.3578 37.9188C89.7873 37.7627 90.263 37.9854 90.4201 38.4164L92.3161 43.618C92.4732 44.049 92.2523 44.5249 91.8227 44.681C91.3931 44.8371 90.9175 44.6144 90.7604 44.1834L88.8643 38.9818C88.7072 38.5509 88.9281 38.0749 89.3578 37.9188ZM92.7708 47.2818C93.2003 47.1257 93.676 47.3484 93.833 47.7794L94.9707 50.9003C95.5467 52.4804 94.7366 54.2253 93.1613 54.7978L55.3033 68.5562C54.8738 68.7123 54.3982 68.4896 54.2411 68.0587C54.084 67.6277 54.305 67.1518 54.7345 66.9957L92.5924 53.2373C93.3084 52.9771 93.6767 52.184 93.4149 51.4657L92.2773 48.3448C92.1202 47.9139 92.3411 47.4379 92.7708 47.2818Z" fill="#2EA9D6"/>
            <path d="M54.2739 29.848C49.8949 32.4797 47.4754 37.6436 48.2564 42.6739C49.7295 52.1663 60.1359 55.8596 65.8233 52.7363C67.6667 51.7232 67.7926 49.0927 66.256 47.6617C64.3769 45.9101 65.027 42.8317 67.4456 41.9527L71.2252 40.5791C72.923 39.9622 73.7873 38.0607 73.1759 36.3686C70.4319 28.9146 61.4911 25.5145 54.2739 29.848ZM54.575 46.6302C53.7356 46.9352 52.8107 46.5046 52.5051 45.6664C52.1996 44.8283 52.631 43.9047 53.4703 43.5997C54.3097 43.2946 55.2347 43.7253 55.5402 44.5634C55.8457 45.4016 55.4144 46.3251 54.575 46.6302ZM53.8832 40.0177C53.0438 40.3228 52.1188 39.8921 51.8133 39.054C51.5078 38.2158 51.9391 37.2923 52.7785 36.9872C53.6179 36.6822 54.5429 37.1128 54.8484 37.9509C55.1539 38.7891 54.7226 39.7126 53.8832 40.0177ZM58.8487 34.7812C58.0093 35.0862 57.0843 34.6556 56.7788 33.8174C56.4733 32.9793 56.9046 32.0557 57.744 31.7507C58.5834 31.4456 59.5084 31.8763 59.8139 32.7144C60.1194 33.5526 59.6881 34.4761 58.8487 34.7812ZM66.0235 35.6057C65.1841 35.9107 64.2591 35.48 63.9536 34.6419C63.6481 33.8038 64.0794 32.8802 64.9188 32.5752C65.7582 32.2701 66.6832 32.7008 66.9887 33.5389C67.2942 34.377 66.8629 35.3006 66.0235 35.6057Z" fill="#83CCE7"/>
            <path d="M55.1216 21.1581C55.1216 19.9217 56.1167 18.9194 57.3442 18.9194H121.801C123.028 18.9194 124.023 19.9217 124.023 21.1581V87.1987C124.023 88.4351 123.028 89.4374 121.801 89.4374H57.3442C56.1167 89.4374 55.1216 88.4351 55.1216 87.1987V21.1581Z" fill="white"/>
            <path d="M0.321906 84.8554C-0.107302 84.4268 -0.107302 83.732 0.321906 83.3034L2.65336 80.9755C3.08256 80.5469 3.77845 80.5469 4.20765 80.9755L6.53911 83.3034C6.96832 83.732 6.96832 84.4268 6.53911 84.8554L4.20765 87.1833C3.77845 87.6119 3.08256 87.6119 2.65336 87.1833L0.321906 84.8554Z" fill="#83CCE7"/>
            <path d="M6.75696 54.08C6.32776 53.6514 6.32776 52.9566 6.75696 52.528L9.08842 50.2001C9.51762 49.7715 10.2135 49.7715 10.6427 50.2001L12.9742 52.528C13.4034 52.9566 13.4034 53.6514 12.9742 54.08L10.6427 56.4079C10.2135 56.8365 9.51762 56.8365 9.08842 56.4079L6.75696 54.08Z" fill="#83CCE7"/>
            <path d="M148.461 54.08C148.032 53.6514 148.032 52.9566 148.461 52.528L150.792 50.2001C151.221 49.7715 151.917 49.7715 152.347 50.2001L154.678 52.528C155.107 52.9566 155.107 53.6514 154.678 54.08L152.347 56.4079C151.917 56.8365 151.221 56.8365 150.792 56.4079L148.461 54.08Z" fill="#83CCE7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M54.2603 21.1395C54.2603 19.4385 55.6296 18.0596 57.3188 18.0596H121.826C123.515 18.0596 124.885 19.4385 124.885 21.1395V87.2176C124.885 88.9186 123.515 90.2975 121.826 90.2975H111.816C111.356 90.2975 110.982 89.9214 110.982 89.4575C110.982 88.9936 111.356 88.6176 111.816 88.6176H121.826C122.594 88.6176 123.216 87.9907 123.216 87.2176V21.1395C123.216 20.3663 122.594 19.7395 121.826 19.7395H57.3188C56.551 19.7395 55.9286 20.3663 55.9286 21.1395V87.2176C55.9286 87.9907 56.551 88.6176 57.3188 88.6176H107.368C107.828 88.6176 108.202 88.9936 108.202 89.4575C108.202 89.9214 107.828 90.2975 107.368 90.2975H57.3188C55.6296 90.2975 54.2603 88.9186 54.2603 87.2176V21.1395Z" fill="#2EA9D6"/>
            <path d="M62.0117 28.0107C62.0117 26.7894 62.9989 25.7993 64.2166 25.7993H114.928C116.146 25.7993 117.133 26.7894 117.133 28.0107V70.0266C117.133 71.248 116.146 72.238 114.928 72.238H64.2166C62.9989 72.238 62.0117 71.248 62.0117 70.0266V28.0107Z" fill="#C3E6F3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M64.1858 26.5999C63.4238 26.5999 62.806 27.2194 62.806 27.9837V70.0533C62.806 70.8177 63.4238 71.4372 64.1858 71.4372H69.1527C69.6099 71.4372 69.9806 71.8089 69.9806 72.2675C69.9806 72.7261 69.6099 73.0978 69.1527 73.0978H64.1858C62.5094 73.0978 61.1504 71.7348 61.1504 70.0533V27.9837C61.1504 26.3023 62.5094 24.9392 64.1858 24.9392H89.5724C90.0296 24.9392 90.4002 25.311 90.4002 25.7695C90.4002 26.2281 90.0296 26.5999 89.5724 26.5999H64.1858ZM102.542 25.7695C102.542 25.311 102.912 24.9392 103.369 24.9392H114.959C116.635 24.9392 117.994 26.3023 117.994 27.9837V52.3398C117.994 52.7984 117.624 53.1701 117.167 53.1701C116.709 53.1701 116.339 52.7984 116.339 52.3398V27.9837C116.339 27.2194 115.721 26.5999 114.959 26.5999H103.369C102.912 26.5999 102.542 26.2281 102.542 25.7695ZM117.167 55.9378C117.624 55.9378 117.994 56.3096 117.994 56.7682V62.3036C117.994 62.7622 117.624 63.134 117.167 63.134C116.709 63.134 116.339 62.7622 116.339 62.3036V56.7682C116.339 56.3096 116.709 55.9378 117.167 55.9378ZM117.167 65.9017C117.624 65.9017 117.994 66.2735 117.994 66.7321V70.0533C117.994 71.7348 116.635 73.0978 114.959 73.0978H74.6715C74.2144 73.0978 73.8438 72.7261 73.8438 72.2675C73.8438 71.8089 74.2144 71.4372 74.6715 71.4372H114.959C115.721 71.4372 116.339 70.8177 116.339 70.0533V66.7321C116.339 66.2735 116.709 65.9017 117.167 65.9017Z" fill="#2EA9D6"/>
            <path d="M86.963 36.3726C81.9466 37.3502 77.9043 41.3764 76.9151 46.3701C75.0479 55.7931 83.5616 62.8175 89.9758 61.8248C92.0551 61.5023 93.0745 59.0735 92.1207 57.204C90.9549 54.9163 92.6203 52.2456 95.1941 52.2456H99.2162C101.023 52.2456 102.486 50.754 102.492 48.9551C102.466 41.0136 95.2294 34.7652 86.963 36.3726ZM81.4975 52.2456C80.6042 52.2456 79.8826 51.525 79.8826 50.6331C79.8826 49.7412 80.6042 49.0206 81.4975 49.0206C82.3907 49.0206 83.1124 49.7412 83.1124 50.6331C83.1124 51.525 82.3907 52.2456 81.4975 52.2456ZM83.1124 45.7956C82.2192 45.7956 81.4975 45.075 81.4975 44.1831C81.4975 43.2912 82.2192 42.5706 83.1124 42.5706C84.0057 42.5706 84.7273 43.2912 84.7273 44.1831C84.7273 45.075 84.0057 45.7956 83.1124 45.7956ZM89.5721 42.5706C88.6789 42.5706 87.9572 41.85 87.9572 40.9581C87.9572 40.0662 88.6789 39.3456 89.5721 39.3456C90.4654 39.3456 91.187 40.0662 91.187 40.9581C91.187 41.85 90.4654 42.5706 89.5721 42.5706ZM96.0318 45.7956C95.1386 45.7956 94.4169 45.075 94.4169 44.1831C94.4169 43.2912 95.1386 42.5706 96.0318 42.5706C96.9251 42.5706 97.6467 43.2912 97.6467 44.1831C97.6467 45.075 96.9251 45.7956 96.0318 45.7956Z" fill="#83CCE7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.9025 69.5841C12.1438 69.5841 11.5287 70.1982 11.5287 70.9558C11.5287 71.7134 12.1438 72.3276 12.9025 72.3276C13.6613 72.3276 14.2764 71.7134 14.2764 70.9558C14.2764 70.1982 13.6613 69.5841 12.9025 69.5841ZM9.88013 70.9558C9.88013 69.2891 11.2333 67.938 12.9025 67.938C14.5718 67.938 15.9249 69.2891 15.9249 70.9558C15.9249 72.6226 14.5718 73.9737 12.9025 73.9737C11.2333 73.9737 9.88013 72.6226 9.88013 70.9558Z" fill="#83CCE7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M145.133 34.3251C144.374 34.3251 143.759 34.9392 143.759 35.6968C143.759 36.4544 144.374 37.0686 145.133 37.0686C145.891 37.0686 146.506 36.4544 146.506 35.6968C146.506 34.9392 145.891 34.3251 145.133 34.3251ZM142.11 35.6968C142.11 34.0301 143.463 32.679 145.133 32.679C146.802 32.679 148.155 34.0301 148.155 35.6968C148.155 37.3635 146.802 38.7147 145.133 38.7147C143.463 38.7147 142.11 37.3635 142.11 35.6968Z" fill="#83CCE7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M134.797 45.5047C134.039 45.5047 133.423 46.1189 133.423 46.8765C133.423 47.6341 134.039 48.2482 134.797 48.2482C135.556 48.2482 136.171 47.6341 136.171 46.8765C136.171 46.1189 135.556 45.5047 134.797 45.5047ZM131.775 46.8765C131.775 45.2098 133.128 43.8586 134.797 43.8586C136.467 43.8586 137.82 45.2098 137.82 46.8765C137.82 48.5432 136.467 49.8943 134.797 49.8943C133.128 49.8943 131.775 48.5432 131.775 46.8765Z" fill="#83CCE7"/>
        </svg>
    )
}

const CarouselItem = ({carouselItem, sendText}) => {

    const send = useCallback(() => {
        if(carouselItem.actionUrl){
            window.open(carouselItem.actionUrl, '_blank')
        }
        else{
            sendText({text: carouselItem.btnText});
        }
    },[carouselItem])

    return (
        <div className={'carousel-item'}>
            <div className={classNames("carousel-image", {hasImage: Boolean(carouselItem.imageUrl)})}
                 style={{backgroundImage: `url(${carouselItem.imageUrl})`}}>
                {!carouselItem.imageUrl && <DefaultImage/>}
            </div>
            <div className="carousel-body">
                <div className="carousel-text-container">
                    <div className="carousel-title">
                        {carouselItem.title}
                    </div>
                    <div className="carousel-text">
                        {carouselItem.description}
                    </div>
                </div>

                {carouselItem.btnText &&
                <button onClick={send}
                        className={'carousel-button'}
                >
                    <span>{carouselItem.btnText}</span>{carouselItem.actionUrl && <LinkIcon/>}
                </button>
                }
            </div>
        </div>
    )
}

export default CarouselItem;