export const parseHTML = function (str) {
    let tmp = document.implementation.createHTMLDocument("");
    tmp.body.innerHTML = str;
    return tmp.body.children;
};

export const log = (message) => {
    if (process.env.NODE_ENV === 'development') {
        console.log(message);
    }
};

export const setCookie = function (name, value) {
    document.cookie = `${name}=${value}; path=/;`;
};

export const getCookie = function (cookieName) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + cookieName.replace(/([.$?*|{}()[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const generateId = function () {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
};


export let animationIds = {};

export const scrollTo = function (element, to, duration, delay) {

    const startTime = Date.now();
    const diffScrollTop = to - element.scrollTop;
    const animId = s4();

    function animateScroll() {
        const currentTime = Date.now();
        const diffTime = currentTime - startTime;
        // const currentScrollTop = element.scrollTop;

        element.scrollTop = to - diffScrollTop * (duration - diffTime) / duration;

        if (diffTime < duration) {
            animationIds[animId] = requestAnimationFrame(animateScroll);
        } else {
            animationIds[animId] = undefined;
        }
    }

    animateScroll();

    return animId;
};

export class ServerError extends Error {
    constructor(message) {
        super(message);
        this.message = message;
        this.name = 'ServerError';
    }
}

export const findNodeInTree = function (current, tagName) {

    let target;

    if (current.tagName.toLocaleLowerCase() === 'body') {
        target = undefined;
    } else if (current.tagName.toLocaleLowerCase() !== tagName.toLocaleLowerCase()) {
        current = current.parentNode;
        target = findNodeInTree(current, tagName);
    } else {
        target = current
    }
    return target;

};

export const getParameterByName = function (name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const cl = ({...rest}) => {
    return console.log
};

export const isDev = () => {
    return process.env.NODE_ENV === 'development' || window.isDev;
};

export const getUserValidName = (firstName, lastName) => {
    let computedName = '';
    if (Boolean(firstName)) {
        computedName += firstName + " ";
    }
    if (Boolean(lastName)) {
        computedName += lastName;
    }
    return computedName;
};
