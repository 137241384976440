import React, {useCallback, useState} from 'react';
import CarouselItem from "./CarouselItem";

const Arrow = ({direction = 'left' /*right*/, ...restProps}) => {
    return (
        <svg className={`carousel-arrow ${direction}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <circle cx="20" cy="20" r="20" transform={`rotate(-90 20 20)`} fill="white"/>
            <path d="M15.0787 19.469L21.1522 13.3959C21.445 13.1031 21.92 13.1031 22.2128 13.3959L22.9212 14.1043C23.2137 14.3968 23.2141 14.8706 22.9225 15.1637L18.1091 19.9993L22.9222 24.8353C23.2141 25.1284 23.2134 25.6021 22.9209 25.8946L22.2125 26.6031C21.9197 26.8959 21.4447 26.8959 21.1519 26.6031L15.0787 20.5296C14.7859 20.2368 14.7859 19.7618 15.0787 19.469Z" fill="#666666"/>
        </svg>
    )
}

const defaultMarginLeft = 65;
const cardWidth = 210;
const cardMargin = 8;

const Carousel = ({items, sendText}) => {

    const [currentItem, setCurrentItem] = useState(0);

    return (
        <div className={'carousel-wrapper'}>
            {currentItem > 0 &&
            <Arrow onClick={()=>setCurrentItem(currentItem - 1)}/>
            }
            {items.length > 1 && currentItem < items.length - 1 &&
            <Arrow direction={'right'}
                   onClick={()=>setCurrentItem(currentItem + 1)}
            />
            }
            <div className={'carousel-container'}
                 style={{marginLeft: defaultMarginLeft, transform: `translateX(-${currentItem * cardWidth + currentItem * cardMargin}px)`}}>
                {items.map((carouselItem, index)=>
                    <CarouselItem key={`carouselItem${carouselItem.title}${index}`}
                                  carouselItem={carouselItem}
                                  sendText={sendText}
                    />
                )}
            </div>
        </div>
    )
}

export default Carousel;