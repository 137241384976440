import React from "react";

export default function SendIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M1.71796 1.10421C0.651345 0.647914 -0.399943 1.77807 0.151754 2.78736L2.27578 6.67644C2.41064 6.92725 2.66197 7.09345 2.94702 7.12972L8.34138 7.79452C8.44559 7.8066 8.52528 7.89424 8.52528 7.99698C8.52528 8.09972 8.44559 8.18735 8.34138 8.19944L2.94702 8.86424C2.66197 8.9005 2.41064 9.06972 2.27578 9.31751L0.151754 13.2126C-0.399943 14.2219 0.651345 15.3521 1.71796 14.8958L15.2621 9.10901C16.246 8.68897 16.246 7.31103 15.2621 6.89099L1.71796 1.10421Z"
        fill="white"
      />
    </svg>
  );
}
