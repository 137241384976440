import React from "react";

function OperatorAvatar() {
    return <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.5 20.25C36.5 29.2246 29.2246 36.5 20.25 36.5C11.2754 36.5 4 29.2246 4 20.25C4 11.2754 11.2754 4 20.25 4C29.2246 4 36.5 11.2754 36.5 20.25Z" fill="url(#paint0_radial_operatoravatar)"/>
        <g filter="url(#filter0_f_operatoravatar)">
            <path d="M35.5 20.25C35.5 28.6723 28.6723 35.5 20.25 35.5C11.8277 35.5 5 28.6723 5 20.25C5 11.8277 11.8277 5 20.25 5C28.6723 5 35.5 11.8277 35.5 20.25Z" fill="url(#paint1_linear_operatoravatar)"/>
        </g>
        <path d="M36.5 20.25C36.5 29.2246 29.2246 36.5 20.25 36.5C11.2754 36.5 4 29.2246 4 20.25C4 11.2754 11.2754 4 20.25 4C29.2246 4 36.5 11.2754 36.5 20.25Z" fill="url(#paint2_linear_operatoravatar)" fillOpacity="0.4"/>
        <path d="M36.5 20.25C36.5 29.2246 29.2246 36.5 20.25 36.5C11.2754 36.5 4 29.2246 4 20.25C4 11.2754 11.2754 4 20.25 4C29.2246 4 36.5 11.2754 36.5 20.25Z" fill="url(#paint3_linear_operatoravatar)" fillOpacity="0.4"/>
        <g filter="url(#filter1_f_operatoravatar)">
            <path d="M34.5 20.25C34.5 28.1201 28.1201 34.5 20.25 34.5C12.3799 34.5 6 28.1201 6 20.25C6 12.3799 12.3799 6 20.25 6C28.1201 6 34.5 12.3799 34.5 20.25Z" fill="url(#paint4_linear_operatoravatar)" fillOpacity="0.95"/>
        </g>
        <g filter="url(#filter2_f_operatoravatar)">
            <path d="M29.5 21.25C29.5 26.3586 25.3586 30.5 20.25 30.5C15.1414 30.5 11 26.3586 11 21.25C11 16.1414 15.1414 12 20.25 12C25.3586 12 29.5 16.1414 29.5 21.25Z" fill="#F7E14E" fillOpacity="0.9"/>
        </g>
        <path d="M16.5 21.3501C16.5 22.8689 15.7165 24.1001 14.75 24.1001C13.7835 24.1001 13 22.8689 13 21.3501C13 19.8313 13.7835 18.6001 14.75 18.6001C15.7165 18.6001 16.5 19.8313 16.5 21.3501Z" fill="#6A300E"/>
        <g filter="url(#filter3_f_operatoravatar)">
            <path d="M15.625 21.3499C15.625 22.3625 15.2332 23.1833 14.75 23.1833C14.2668 23.1833 13.875 22.3625 13.875 21.3499C13.875 20.3374 14.2668 19.5166 14.75 19.5166C15.2332 19.5166 15.625 20.3374 15.625 21.3499Z" fill="#B0733D"/>
        </g>
        <path d="M15.7 27.5C20.7 30.5 24.7 27.5 24.7 27.5C24.7 27.5 20.7 35.5 15.7 27.5Z" fill="#673413"/>
        <g filter="url(#filter4_f_operatoravatar)">
            <path d="M17.2 28.5C20.8111 30.6094 23.7 28.5 23.7 28.5C23.7 28.5 20.7 33 17.2 28.5Z" fill="#9B5A23"/>
        </g>
        <path d="M28.9577 21.7563C27.543 19.6378 23.8969 19.6865 22.5843 21.9027C22.3876 22.2347 22.5514 22.6009 22.907 22.4512C23.6459 22.1404 25.1083 21.3301 28.5415 22.2478C28.8608 22.3331 29.1412 22.0311 28.9577 21.7563Z" fill="#5F2C10"/>
        <g filter="url(#filter5_f_operatoravatar)">
            <path d="M28.2665 21.5499C27.103 20.6288 24.8441 20.3531 23.5497 21.3143C23.2067 21.5689 23.4929 21.9657 23.9008 21.839L23.9143 21.8348C25.045 21.4835 25.6369 21.2996 28.039 21.9132C28.3032 21.9806 28.4803 21.7191 28.2665 21.5499Z" fill="#A06025"/>
        </g>
        <path d="M10.1707 17.3621C10.2841 16.3789 12.6878 12.3062 15.2494 12.9103C15.3024 12.9228 15.3558 12.9403 15.4011 12.9704C15.8033 13.2385 15.5019 13.92 14.9119 13.9678C12.6938 14.1477 11.463 16.6493 10.8885 17.61C10.7701 17.8079 10.5438 17.9679 10.3453 17.8506C10.2302 17.7825 10.1384 17.6428 10.1707 17.3621Z" fill="#653212"/>
        <g filter="url(#filter6_f_operatoravatar)">
            <path d="M10.64 17.1838C10.7235 16.5605 12.9782 12.9964 14.7673 13.3178C14.8176 13.3268 14.867 13.3425 14.9123 13.3662C15.2078 13.5213 15.2861 13.7971 14.8829 13.8298C13.2517 13.9621 11.9171 15.5304 11.4802 16.0259C11.4254 16.088 11.3776 16.1543 11.3371 16.2265C11.1498 16.5603 10.5937 17.5294 10.64 17.1838Z" fill="#94582B" fillOpacity="0.9"/>
        </g>
        <path d="M29.9425 17.75C29.3545 16.954 25.2416 14.6196 23.3212 16.4193C23.2816 16.4565 23.2439 16.4983 23.2197 16.547C23.0045 16.9798 23.6052 17.4206 24.1407 17.1682C26.1537 16.2195 28.4669 17.7756 29.4436 18.3225C29.6448 18.4351 29.9208 18.4611 30.0344 18.2605C30.1004 18.1442 30.1104 17.9773 29.9425 17.75Z" fill="#653212"/>
        <g filter="url(#filter7_f_operatoravatar)">
            <path d="M29.4468 17.829C29.064 17.3301 25.3336 15.3627 23.9423 16.5325C23.9032 16.5654 23.8682 16.6036 23.8407 16.6467C23.6617 16.9284 23.7312 17.2065 24.0971 17.034C25.5775 16.3363 27.5159 17.0315 28.1415 17.2435C28.22 17.2701 28.2944 17.3037 28.3656 17.3462C28.6942 17.5423 29.6591 18.1057 29.4468 17.829Z" fill="#94582B" fillOpacity="0.9"/>
        </g>
        <defs>
            <filter id="filter0_f_operatoravatar" x="4" y="4" width="32.5" height="32.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.5" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter1_f_operatoravatar" x="5" y="5" width="30.5" height="30.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.5" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter2_f_operatoravatar" x="5" y="6" width="30.5" height="30.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="3" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter3_f_operatoravatar" x="11.875" y="17.5166" width="5.75" height="7.66667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="1" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter4_f_operatoravatar" x="16.2" y="27.5" width="8.5" height="4" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.5" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter5_f_operatoravatar" x="22.397" y="19.7134" width="6.96472" height="3.21066" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.5" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter6_f_operatoravatar" x="10.1372" y="12.7974" width="5.52269" height="4.95981" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.25" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter7_f_operatoravatar" x="23.2461" y="15.667" width="6.73092" height="2.73715" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.25" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <radialGradient id="paint0_radial_operatoravatar" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.25 20.25) rotate(90) scale(16.25)">
                <stop offset="0.791197" stopColor="#EABB32"/>
                <stop offset="0.97702" stopColor="#CB6414"/>
            </radialGradient>
            <linearGradient id="paint1_linear_operatoravatar" x1="20.25" y1="5" x2="20.25" y2="35.5" gradientUnits="userSpaceOnUse">
                <stop offset="0.256986" stopColor="#EDBB35"/>
                <stop offset="1" stopColor="#CB6414"/>
            </linearGradient>
            <linearGradient id="paint2_linear_operatoravatar" x1="20.25" y1="4" x2="20.25" y2="36.5" gradientUnits="userSpaceOnUse">
                <stop offset="0.290672" stopColor="#EABB32"/>
                <stop offset="1" stopColor="#DD7C1C"/>
            </linearGradient>
            <linearGradient id="paint3_linear_operatoravatar" x1="20.25" y1="4" x2="20.25" y2="36.5" gradientUnits="userSpaceOnUse">
                <stop offset="0.290672" stopColor="#EABB32"/>
                <stop offset="1" stopColor="#DD7C1C"/>
            </linearGradient>
            <linearGradient id="paint4_linear_operatoravatar" x1="20.25" y1="6" x2="20.25" y2="34.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="0.368838" stopColor="white" stopOpacity="0.5"/>
                <stop offset="0.725487" stopColor="white" stopOpacity="0.1"/>
                <stop offset="0.982024" stopColor="white" stopOpacity="0.05"/>
            </linearGradient>
        </defs>
    </svg>

}

export default React.memo(OperatorAvatar);