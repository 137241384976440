import React, { Component } from 'react';
import classNames from "classnames";

class Bubble extends Component {
    state = {show: false};

    componentDidMount() {
        const { options: {bubble: {delay}} } = this.props.data;

        if (delay !== 0) {
            setTimeout(() => this.setState({show: true}), delay * 1000)
        } else {
            this.setState({show: true});
        }
    }

    render () {
        const { data: {options: {bubble}} } = this.props;
        return (
            <>
                {bubble.bubbletext && <div className={classNames("justwidget--asst-bubble justwidget--bubble-text--desktop", {"justwidget_hidden": !this.state.show})}>
                    <p className="justwidget--bubble-text">
                        {bubble.bubbletext}
                    </p>
                </div>}
                {bubble.mobileBubbletext && <div className={classNames("justwidget--asst-bubble justwidget--bubble-text--mobile", {"justwidget_hidden": !this.state.show})}>
                    <p className="justwidget--bubble-text">
                        {bubble.mobileBubbletext}
                    </p>
                </div>}
            </>
        )
    }
};

export default Bubble;