import React from "react";

export default function DefaultLogo() {
    return <div className="justwidget--asst-pic">
        <svg
            className="justwidget--asst-pic_svg-desktop"
            viewBox="0 0 120 120">
            <path
                d="M46.4,64.29A11.43,11.43,0,0,0,52,65.37H68.69a11.43,11.43,0,0,0,5.55-1.08A6.52,6.52,0,0,0,77,61.62,11,11,0,0,0,78,56.15V54.61A11,11,0,0,0,77,49.14a6.52,6.52,0,0,0-2.71-2.67,11.43,11.43,0,0,0-5.55-1.08H52a11.43,11.43,0,0,0-5.55,1.08,6.44,6.44,0,0,0-2.7,2.67,10.87,10.87,0,0,0-1.1,5.47v1.54a10.87,10.87,0,0,0,1.1,5.47A6.44,6.44,0,0,0,46.4,64.29Zm21.51-13.9a5,5,0,1,1-5.06,5A5,5,0,0,1,67.91,50.39Zm-15.18,0a5,5,0,1,1-5.07,5A5,5,0,0,1,52.73,50.39ZM49.78,32.08A2.11,2.11,0,1,1,47.66,30,2.1,2.1,0,0,1,49.78,32.08Zm21.09,0A2.11,2.11,0,1,1,73,34.16,2.09,2.09,0,0,1,70.87,32.08ZM60,0h0A60,60,0,0,0,0,60H0a60,60,0,0,0,60,60h0a60,60,0,0,0,60-60h0A60,60,0,0,0,60,0ZM37.54,54.61a15.77,15.77,0,0,1,1.69-7.83A11.39,11.39,0,0,1,44,42.07a15.27,15.27,0,0,1,6.87-1.65l-2.43-3a5.19,5.19,0,0,1-.8.06,5.41,5.41,0,1,1,5.49-5.41,5.36,5.36,0,0,1-1,3.18l4.17,5.14h8l3.95-5.58a5.46,5.46,0,1,1,10.22-2.74,5.49,5.49,0,0,1-6.84,5.24l-2.2,3.09a15.53,15.53,0,0,1,7.2,1.66,11.36,11.36,0,0,1,4.79,4.72,15.7,15.7,0,0,1,1.69,7.82v1.54A15.7,15.7,0,0,1,81.41,64a11.36,11.36,0,0,1-4.79,4.72,16.23,16.23,0,0,1-7.93,1.67H52A16.23,16.23,0,0,1,44,68.69,11.41,11.41,0,0,1,39.23,64a15.7,15.7,0,0,1-1.69-7.82ZM80.77,93.33c0-12.84-2.49-14.65-20.92-14.65S39.23,80.43,39.23,93.33H34.17c0-16,4.87-19.64,25.68-19.64s26,3.66,26,19.64Z"
                className="juswidget-fill" fill="#4a90e2" fillRule="evenodd"/>
        </svg>
    </div>
};