import React from 'react';
import DefaultLogo from '../icons/DefaultLogo';
import Bubble from './Bubble';
import { CloseIconButton } from '../CloseIconButton';

export default function index({
  logo,
  toggleWidget,
  showBubble,
  data,
  closeControls,
  showCloseButton,
}) {
  if (!closeControls.show) return null;

  return (
    <div
      className={`justwidget--asst justwidget--toggle-action`}
      onClick={toggleWidget}
    >
      <div className="justwidget--asst-pic-waves" />
      {showCloseButton && <CloseIconButton />}
      {!!logo ? (
        <div
          className="justwidget--asst-pic"
          style={{ backgroundImage: `url(${logo})` }}
        />
      ) : (
        <DefaultLogo />
      )}
      {showBubble && <Bubble data={{ ...data }} />}
    </div>
  );
}
