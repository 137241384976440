import { useCallback, useEffect, useRef, useState } from "react";

const statusMap = {
  stopped: "stopped",
  aborted: "aborted",
  send: "send",
};

export const useAudioRecorder = (maxSecondsRecording) => {
  const [audioFile, setAudioFile] = useState(null);
  const [secondsRecording, setSecondsRecording] = useState(0);
  const [isMaxSecondsLimit, setIsMaxSecondsLimit] = useState(false);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const statusRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });

        mediaRecorderRef.current.ondataavailable = onRecordingActive;
        mediaRecorderRef.current.onstop = onRecordingStop;

        mediaRecorderRef.current.start();
      })
      .catch((error) => {
        console.log("An error occurred while creating an audio stream", error);
      });

    const onRecordingActive = ({ data }) => audioChunksRef.current.push(data);
    const onRecordingStop = () => {
      if (statusRef.current === statusMap.aborted) cleanUpRefs();
      if (statusRef.current === statusMap.send) sendFile();
    };

    const cleanUpRefs = () => {
      mediaRecorderRef.current = null;
      audioChunksRef.current = [];
      statusRef.current = null;
    };

    return () => cleanUpRefs();
  }, []); // eslint-disable-line

  useEffect(() => {
    const intervalId = setInterval(
      () => setSecondsRecording((prevSeconds) => prevSeconds + 1),
      1000
    );
    if (isMaxSecondsLimit) clearInterval(intervalId);

    return () => clearInterval(intervalId);
  }, [isMaxSecondsLimit]);

  useEffect(() => {
    if (secondsRecording >= maxSecondsRecording) {
      statusRef.current = statusMap.stopped;
      stopAndTurnOffMicrophone();
      setIsMaxSecondsLimit(true);
    }
  }, [maxSecondsRecording, secondsRecording, stopAndTurnOffMicrophone]);

  const sendFile = useCallback(() => {
    const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
    const audioFile = new File([audioBlob], "recording.webm", {
      type: "audio/webm",
    });

    setAudioFile(audioFile);
  }, []);

  const stopAndTurnOffMicrophone = useCallback(() => {
    if (mediaRecorderRef.current?.stream) {
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
  }, []);

  const sendVoiceMessage = useCallback(() => {
    if (statusRef.current === statusMap.stopped) sendFile();
    else {
      statusRef.current = statusMap.send;
      stopAndTurnOffMicrophone();
    }
  }, [sendFile, stopAndTurnOffMicrophone]);

  const abortRecording = useCallback(() => {
    statusRef.current = statusMap.aborted;
    stopAndTurnOffMicrophone();
  }, [stopAndTurnOffMicrophone]);

  return {
    sendVoiceMessage,
    abortRecording,
    secondsRecording,
    audioFile,
    isMaxSecondsLimit,
  };
};
