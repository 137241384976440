import React from "react";

const Setting = () => (
  <svg width="20" height="21" viewBox="0 0 20 21">
    <path
      d="M14.5833 17.7916C13.7778 17.7916 13.0903 17.5069 12.5208 16.9374C11.9514 16.368 11.6667 15.6805 11.6667 14.8749C11.6667 14.0694 11.9514 13.3819 12.5208 12.8124C13.0903 12.243 13.7778 11.9583 14.5833 11.9583C15.3889 11.9583 16.0764 12.243 16.6458 12.8124C17.2153 13.3819 17.5 14.0694 17.5 14.8749C17.5 15.6805 17.2153 16.368 16.6458 16.9374C16.0764 17.5069 15.3889 17.7916 14.5833 17.7916ZM14.5784 16.5416C15.04 16.5416 15.434 16.38 15.7604 16.0569C16.0868 15.7338 16.25 15.3414 16.25 14.8798C16.25 14.4182 16.0884 14.0242 15.7653 13.6978C15.4422 13.3714 15.0498 13.2083 14.5882 13.2083C14.1266 13.2083 13.7326 13.3698 13.4062 13.6929C13.0799 14.0161 12.9167 14.4084 12.9167 14.87C12.9167 15.3316 13.0782 15.7256 13.4014 16.052C13.7245 16.3784 14.1168 16.5416 14.5784 16.5416ZM2.5 15.4999V14.2499H10V15.4999H2.5ZM5.41667 9.04158C4.61111 9.04158 3.92361 8.75686 3.35417 8.18742C2.78472 7.61797 2.5 6.93047 2.5 6.12492C2.5 5.31936 2.78472 4.63186 3.35417 4.06242C3.92361 3.49297 4.61111 3.20825 5.41667 3.20825C6.22222 3.20825 6.90972 3.49297 7.47917 4.06242C8.04861 4.63186 8.33333 5.31936 8.33333 6.12492C8.33333 6.93047 8.04861 7.61797 7.47917 8.18742C6.90972 8.75686 6.22222 9.04158 5.41667 9.04158ZM5.41177 7.79158C5.87337 7.79158 6.26736 7.63002 6.59375 7.3069C6.92014 6.98379 7.08333 6.59142 7.08333 6.12981C7.08333 5.66822 6.92177 5.27422 6.59865 4.94784C6.27553 4.62145 5.88317 4.45825 5.42156 4.45825C4.95996 4.45825 4.56597 4.61981 4.23958 4.94294C3.91319 5.26605 3.75 5.65841 3.75 6.12002C3.75 6.58162 3.91156 6.97561 4.23469 7.302C4.5578 7.62839 4.95016 7.79158 5.41177 7.79158ZM10 6.74992V5.49992H17.5V6.74992H10Z"
      fill="black"
    />
  </svg>
);

export default Setting;
