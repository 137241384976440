import React from 'react';
import './closeButton.css';

export const CloseIconButton = () => {
  const closeWidget = (e) => {
    e.stopPropagation();

    const widget = document.querySelector('.justwidget');
    if (widget) {
      widget.style.display = 'none';
    }
  };

  return (
    <div className="close-icon-button-wrapper" data-test-id="Justwidget.CloseButton" onClick={closeWidget}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.48735 5.00029L9.8536 1.63404C10.0483 1.43935 10.0483 1.12342 9.8536 0.928417L9.07142 0.146229C8.87673 -0.0484582 8.56079 -0.0484582 8.36579 0.146229L4.99985 3.51279L1.6336 0.146542C1.43892 -0.0481457 1.12298 -0.0481457 0.927978 0.146542L0.146103 0.928417C-0.0485841 1.1231 -0.0485841 1.43904 0.146103 1.63404L3.51235 5.00029L0.146103 8.36654C-0.0485841 8.56123 -0.0485841 8.87717 0.146103 9.07217L0.928291 9.85435C1.12298 10.049 1.43892 10.049 1.63392 9.85435L4.99985 6.48779L8.3661 9.85404C8.56079 10.0487 8.87673 10.0487 9.07173 9.85404L9.85392 9.07185C10.0486 8.87717 10.0486 8.56123 9.85392 8.36623L6.48735 5.00029Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
