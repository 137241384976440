import React from 'react';
import ReactDOM from 'react-dom';
import localization from "../../../../localization";

const t = localization.translate;

const StacktraceOverlay=({data, hideErrorOverlay, stacktrace})=>{

    let container = document.getElementById('JustWidgetStacktraceOverlay');
    if(!container){
        container = document.createElement('div');
        container.id = 'JustWidgetStacktraceOverlay';
        container = document.body.appendChild(container);
    }

    const copyLog = () => {
        let supported = document.queryCommandSupported('copy');
        if (supported) {
            const logTextarea = document.querySelector('.justwidget--stacktrace-overlay-text');
            const range = document.getSelection().getRangeAt(0);
            range.selectNode(logTextarea);
            window.getSelection().addRange(range);
            document.execCommand("copy");
        }
    };

    return ReactDOM.createPortal(
        <div className="justwidget--stacktrace-overlay">
            <div className="justwidget--stacktrace-overlay-content">
                <span className="justwidget--stacktrace-overlay--close" onClick={hideErrorOverlay}/>
                <div className="justwidget--stacktrace-overlay-main">
                    <h1 className="justwidget--stacktrace-headline">{data.options.forcedLanguage ? t('captions.errorLog') : data.options.captions.errorLog}</h1>
                    <div className="justwidget--stacktrace-overlay-text">{stacktrace}</div>
                </div>
                <div className="justwidget--stacktrace-overlay-bottom">
                    <button className="justwidget--stacktrace-overlay__copy-log" onClick={copyLog}>
                        {data.options.forcedLanguage ? t('captions.copyLog') : data.options.captions.copyLog}
                    </button>
                    <button className="justwidget--stacktrace-overlay__close" onClick={hideErrorOverlay}>
                        {data.options.forcedLanguage ? t('captions.closeLog') : data.options.captions.closeLog}
                    </button>
                </div>
            </div>
        </div>
    , container);
}


export default StacktraceOverlay;
