import React from "react";

const VoiceMessagesDenied = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <path
      d="M6.39995 3.99999C6.39995 3.11749 7.11745 2.39999 7.99995 2.39999C8.88245 2.39999 9.59995 3.11749 9.59995 3.99999V7.73249L10.3675 8.33499C10.3825 8.22499 10.4 8.11499 10.4 7.99999V3.99999C10.4 2.67499 9.32495 1.59999 7.99995 1.59999C6.67495 1.59999 5.59995 2.67499 5.59995 3.99999V4.58249L6.39995 5.21249V3.99999ZM12 7.99999V6.59999C12 6.48999 11.91 6.39999 11.7999 6.39999H11.4C11.29 6.39999 11.2 6.48999 11.2 6.59999V7.99999C11.2 8.30749 11.1425 8.59999 11.0599 8.88249L11.735 9.41499C11.905 8.97499 12 8.49999 12 7.99999ZM10.2 13.6H8.39995V11.975C9.07995 11.9075 9.70245 11.6575 10.24 11.2925L9.57995 10.7725C9.05495 11.0725 8.43995 11.2375 7.78245 11.1925C6.08245 11.08 4.79995 9.58999 4.79995 7.88749V7.00749L4.08745 6.44499C4.03745 6.48249 3.99995 6.53249 3.99995 6.59749V7.85499C3.99995 9.93999 5.54245 11.7575 7.59995 11.965V13.6H5.79995C5.68995 13.6 5.59995 13.69 5.59995 13.8V14.2C5.59995 14.31 5.68995 14.4 5.79995 14.4H10.2C10.31 14.4 10.4 14.31 10.4 14.2V13.8C10.4 13.69 10.31 13.6 10.2 13.6ZM7.99995 10.4C8.31495 10.4 8.61245 10.335 8.88745 10.225L8.08245 9.58999C8.05495 9.59249 8.02995 9.59749 7.99995 9.59749C7.21745 9.59749 6.56745 9.03249 6.42995 8.28749L5.59995 7.63749V7.99999C5.59995 9.32499 6.67495 10.4 7.99995 10.4ZM15.9249 13.73L0.574954 1.64499C0.489954 1.57499 0.362454 1.58749 0.294954 1.67499L0.0449545 1.98749C-0.0250455 2.07499 -0.0100455 2.19999 0.0749545 2.26749L15.4249 14.355C15.5099 14.425 15.6374 14.41 15.7049 14.325L15.9549 14.0125C16.0249 13.925 16.0124 13.8 15.9249 13.73Z"
      fill="#333C45"
    />
  </svg>
);

export default VoiceMessagesDenied;
