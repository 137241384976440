import React from 'react';
import classNames from "classnames";
import ButtonLinkIcon from "../icons/ButtonLinkIcon";
import {t} from '../../../../localization'
import {StopTestIcon} from "../icons/common";

export function Button({button, onClick}) {
    const click = () => onClick(button);

    return (
        !!button.url
            ? <a
                target="_blank"
                rel="noopener noreferrer"
                href={button.url}
                className="justwidget--button justwidget--button-url">
                <span>{button.text}</span>
                <ButtonLinkIcon/>
            </a>
            : <button
                type="button"
                onClick={click}
                className="justwidget--button">
                <span>{button.text || button}</span>
            </button>
    )
};

export default function Buttons({buttons, buttonClick, classname, options, testMode, toggleWidget}) {
    return <>
        {buttons && buttons.length > 0 &&
            <div className={classNames("justwidget--buttons", classname, {justwidget_hidden: buttons.length === 0})}>
                {buttons.map((button, key) => <Button
                    button={button}
                    key={`buttonKey${key}`}
                    onClick={buttonClick}/>
                )}
            </div>
        }

        {testMode && options.closeControls.show && <div className='justwidget--buttons--closeContainer'>
            <button
                type="button"
                onClick={toggleWidget}
                className="justwidget--button closeWidget">
                <StopTestIcon/> <span>{t('Button:StopTest')}</span>
            </button>
        </div>
        }
    </>
}
