import React, { memo, useEffect, useState } from 'react';

export const PreviewImage = memo(({ file }) => {
  const [imageBase64, setImageBase64] = useState(null);
  const hasImage = file?.type.includes('image');

  useEffect(() => {
    if (file && !imageBase64) {
      const reader = new FileReader();
      reader.onload = (event) => setImageBase64(event.target.result);
      reader.readAsDataURL(file);
    }
  }, [file, imageBase64]);

  if (!imageBase64 || !hasImage) {
    return null;
  }

  return <img width={36} height={36} src={imageBase64} alt="clipboardFile" />;
});
