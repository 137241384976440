import React from 'react';
import './RoundSpinner.scss'

const RoundSpinner = () => {

    return (
        <svg
            className='justwidget--round__spinner'
            width={16}
            height={16}
            viewBox='0 0 40 40'
            fill='none'
        >
            <path
                opacity='0.3'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5ZM0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
                fill={'red'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.7618 0.997777C16.7654 0.0117154 19.96 -0.248271 23.0836 0.239143C26.2071 0.726557 29.1707 1.94748 31.7311 3.80179C34.2915 5.65609 36.3759 8.09094 37.8132 10.9066C39.2506 13.7223 40 16.8386 40 20C40 21.3807 38.8807 22.5 37.5 22.5C36.1193 22.5 35 21.3807 35 20C35 17.629 34.438 15.2917 33.3599 13.18C32.2819 11.0682 30.7186 9.24207 28.7983 7.85134C26.878 6.46061 24.6554 5.54492 22.3127 5.17936C19.97 4.8138 17.5741 5.00879 15.3213 5.74833C14.0095 6.17899 12.597 5.46466 12.1663 4.15283C11.7356 2.841 12.45 1.42844 13.7618 0.997777Z'
                fill={'red'}
            />
        </svg>
    );
};

export default RoundSpinner;