import React from "react";

function OperatorAvatar() {
    return<svg width="100%" height="100%" viewBox="0 0 40 40" fill="none">
        <g filter="url(#filter0_i_operatoravatar)">
            <path d="M31.5 16H35.3C35.6866 16 36 16.3134 36 16.7V24.3C36 24.6866 35.6866 25 35.3 25H31.5V16Z" fill="url(#paint0_linear_operatoravatar)"/>
        </g>
        <g filter="url(#filter1_i_operatoravatar)">
            <path d="M8.5 16H4.7C4.3134 16 4 16.3134 4 16.7V24.3C4 24.6866 4.3134 25 4.7 25H8.5V16Z" fill="url(#paint1_linear_operatoravatar)"/>
        </g>
        <g filter="url(#filter2_i_operatoravatar)">
            <path d="M6.5 13.5C6.5 10.1863 9.18629 7.5 12.5 7.5H27.5C30.8137 7.5 33.5 10.1863 33.5 13.5V28.5C33.5 31.8137 30.8137 34.5 27.5 34.5H12.5C9.18629 34.5 6.5 31.8137 6.5 28.5V13.5Z" fill="url(#paint2_linear_operatoravatar)"/>
        </g>
        <g filter="url(#filter3_d_operatoravatar)">
            <path d="M8 14.5C8 11.4624 10.4624 9 13.5 9H26.5C29.5376 9 32 11.4624 32 14.5V27.5C32 30.5376 29.5376 33 26.5 33H13.5C10.4624 33 8 30.5376 8 27.5V14.5Z" fill="url(#paint3_linear_operatoravatar)"/>
        </g>
        <g filter="url(#filter4_i_operatoravatar)">
            <path d="M17 17.5C17 19.433 15.433 21 13.5 21C11.567 21 10 19.433 10 17.5C10 15.567 11.567 14 13.5 14C15.433 14 17 15.567 17 17.5Z" fill="#18758B"/>
        </g>
        <g filter="url(#filter5_f_operatoravatar)">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.1665 15.1667C11.7854 14.5479 12.6247 14.2002 13.5 14.2002C14.3752 14.2002 15.2145 14.5479 15.8334 15.1667C16.4523 15.7856 16.8 16.625 16.8 17.5002H16C16 16.8372 15.7366 16.2013 15.2677 15.7324C14.7989 15.2636 14.163 15.0002 13.5 15.0002C12.8369 15.0002 12.201 15.2636 11.7322 15.7324C11.2633 16.2013 11 16.8372 11 17.5002H10.2C10.2 16.625 10.5476 15.7856 11.1665 15.1667Z" fill="#20BDD2"/>
        </g>
        <g filter="url(#filter6_d_operatoravatar)">
            <path d="M16 17.5C16 18.8807 14.8807 20 13.5 20C12.1193 20 11 18.8807 11 17.5C11 16.1193 12.1193 15 13.5 15C14.8807 15 16 16.1193 16 17.5Z" fill="url(#paint4_linear_operatoravatar)"/>
        </g>
        <g filter="url(#filter7_i_operatoravatar)">
            <path d="M30 17.5C30 19.433 28.433 21 26.5 21C24.567 21 23 19.433 23 17.5C23 15.567 24.567 14 26.5 14C28.433 14 30 15.567 30 17.5Z" fill="#18758B"/>
        </g>
        <g filter="url(#filter8_f_operatoravatar)">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.1665 15.1667C24.7854 14.5479 25.6247 14.2002 26.5 14.2002C27.3752 14.2002 28.2145 14.5479 28.8334 15.1667C29.4523 15.7856 29.8 16.625 29.8 17.5002H29C29 16.8372 28.7366 16.2013 28.2677 15.7324C27.7989 15.2636 27.163 15.0002 26.5 15.0002C25.8369 15.0002 25.201 15.2636 24.7322 15.7324C24.2633 16.2013 24 16.8372 24 17.5002H23.2C23.2 16.625 23.5476 15.7856 24.1665 15.1667Z" fill="#20BDD2"/>
        </g>
        <g filter="url(#filter9_d_operatoravatar)">
            <path d="M29 17.5C29 18.8807 27.8807 20 26.5 20C25.1193 20 24 18.8807 24 17.5C24 16.1193 25.1193 15 26.5 15C27.8807 15 29 16.1193 29 17.5Z" fill="url(#paint5_linear_operatoravatar)"/>
        </g>
        <g filter="url(#filter10_d_operatoravatar)">
            <path d="M20 18L22.5 22.5H17.5L20 18Z" fill="url(#paint6_radial_operatoravatar)"/>
        </g>
        <g filter="url(#filter11_d_operatoravatarii)">
            <path d="M14 26.75C14 25.7835 14.7835 25 15.75 25H23.75C24.7165 25 25.5 25.7835 25.5 26.75C25.5 27.7165 24.7165 28.5 23.75 28.5H15.75C14.7835 28.5 14 27.7165 14 26.75Z" fill="white"/>
        </g>
        <path d="M16.5 25H17V28.5H16.5V25Z" fill="#232921"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.45 24.9502H17.05V28.5502H16.45V24.9502ZM16.55 25.0502V28.4502H16.95V25.0502H16.55Z" fill="black" fillOpacity="0.3"/>
        <path d="M19.5 25H20V28.5H19.5V25Z" fill="#232921"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.45 24.9502H20.05V28.5502H19.45V24.9502ZM19.55 25.0502V28.4502H19.95V25.0502H19.55Z" fill="black" fillOpacity="0.3"/>
        <path d="M22.5 25H23V28.5H22.5V25Z" fill="#232921"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.45 24.9502H23.05V28.5502H22.45V24.9502ZM22.55 25.0502V28.4502H22.95V25.0502H22.55Z" fill="black" fillOpacity="0.3"/>
        <g filter="url(#filter12_d_operatoravatar)">
            <path d="M20 4.5L23.5 7.5L23.344 7.58662C21.2084 8.77214 18.6049 8.73919 16.5 7.5L20 4.5Z" fill="url(#paint7_radial_operatoravatar)"/>
        </g>
        <g filter="url(#filter13_i_operatoravatar)">
            <path d="M22.5 4.5C22.5 5.88071 21.3807 7 20 7C18.6193 7 17.5 5.88071 17.5 4.5C17.5 3.11929 18.6193 2 20 2C21.3807 2 22.5 3.11929 22.5 4.5Z" fill="url(#paint8_linear_operatoravatar)"/>
        </g>
        <g filter="url(#filter14_f_operatoravatar)">
            <path d="M19.5 3.5C19.5 3.77614 19.2761 4 19 4C18.7239 4 18.5 3.77614 18.5 3.5C18.5 3.22386 18.7239 3 19 3C19.2761 3 19.5 3.22386 19.5 3.5Z" fill="white" fillOpacity="0.6"/>
        </g>
        <defs>
            <filter id="filter0_i_operatoravatar" x="31.5" y="16" width="4.5" height="9" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.4"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_i_operatoravatarnnerShadow"/>
            </filter>
            <filter id="filter1_i_operatoravatar" x="4" y="16" width="4.5" height="9" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.4"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_i_operatoravatarnnerShadow"/>
            </filter>
            <filter id="filter2_i_operatoravatar" x="6.5" y="7.5" width="27" height="27" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_i_operatoravatarnnerShadow"/>
            </filter>
            <filter id="filter3_d_operatoravatar" x="7" y="8" width="26" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_d_operatoravatarropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_d_operatoravatarropShadow" result="shape"/>
            </filter>
            <filter id="filter4_i_operatoravatar" x="10" y="14" width="7" height="7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.4"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_i_operatoravatarnnerShadow"/>
            </filter>
            <filter id="filter5_f_operatoravatar" x="9.89995" y="13.9002" width="7.2" height="3.9" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.15" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter6_d_operatoravatar" x="10" y="14" width="7" height="7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_d_operatoravatarropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_d_operatoravatarropShadow" result="shape"/>
            </filter>
            <filter id="filter7_i_operatoravatar" x="23" y="14" width="7" height="7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.4"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_i_operatoravatarnnerShadow"/>
            </filter>
            <filter id="filter8_f_operatoravatar" x="22.9" y="13.9002" width="7.2" height="3.9" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.15" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <filter id="filter9_d_operatoravatar" x="23" y="14" width="7" height="7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_d_operatoravatarropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_d_operatoravatarropShadow" result="shape"/>
            </filter>
            <filter id="filter10_d_operatoravatar" x="17" y="17.5" width="6" height="5.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.25"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_d_operatoravatarropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_d_operatoravatarropShadow" result="shape"/>
            </filter>
            <filter id="filter11_d_operatoravatarii" x="13.9" y="24.9" width="11.7" height="3.7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.05"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_d_operatoravatarropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_d_operatoravatarropShadow" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_i_operatoravatarnnerShadow"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="effect2_i_operatoravatarnnerShadow" result="effect3_i_operatoravatarnnerShadow"/>
            </filter>
            <filter id="filter12_d_operatoravatar" x="15.5" y="4.5" width="9" height="5.95313" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_d_operatoravatarropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_d_operatoravatarropShadow" result="shape"/>
            </filter>
            <filter id="filter13_i_operatoravatar" x="17.5" y="2" width="5" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_i_operatoravatarnnerShadow"/>
            </filter>
            <filter id="filter14_f_operatoravatar" x="18" y="2.5" width="2" height="2" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.25" result="effect1_f_operatoravataroregroundBlur"/>
            </filter>
            <linearGradient id="paint0_linear_operatoravatar" x1="33.5" y1="16" x2="33.5" y2="25" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C92F32"/>
                <stop offset="1" stopColor="#2C1920"/>
            </linearGradient>
            <linearGradient id="paint1_linear_operatoravatar" x1="6.5" y1="16" x2="6.5" y2="25" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C92F32"/>
                <stop offset="1" stopColor="#2C1920"/>
            </linearGradient>
            <linearGradient id="paint2_linear_operatoravatar" x1="20" y1="8" x2="20" y2="34" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BBD0CB"/>
                <stop offset="1" stopColor="#1C373B"/>
            </linearGradient>
            <linearGradient id="paint3_linear_operatoravatar" x1="20" y1="9" x2="20" y2="33.5" gradientUnits="userSpaceOnUse">
                <stop offset="0.0514222" stopColor="#E6FDF7"/>
                <stop offset="1" stopColor="#3D898F"/>
            </linearGradient>
            <linearGradient id="paint4_linear_operatoravatar" x1="13.5" y1="15" x2="13.5" y2="20" gradientUnits="userSpaceOnUse">
                <stop offset="0.17451" stopColor="white"/>
                <stop offset="0.89151" stopColor="#B9E4DF"/>
            </linearGradient>
            <linearGradient id="paint5_linear_operatoravatar" x1="26.5" y1="15" x2="26.5" y2="20" gradientUnits="userSpaceOnUse">
                <stop offset="0.17451" stopColor="white"/>
                <stop offset="0.89151" stopColor="#B9E4DF"/>
            </linearGradient>
            <radialGradient id="paint6_radial_operatoravatar" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.0714 21) rotate(90) scale(3 2.57143)">
                <stop stopColor="#AE3F32"/>
                <stop offset="1" stopColor="#782726"/>
            </radialGradient>
            <radialGradient id="paint7_radial_operatoravatar" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.8971 6.25) rotate(88.8773) scale(5.25101 6.29655)">
                <stop offset="0.255302" stopColor="#413449"/>
                <stop offset="0.763651" stopColor="#171319"/>
            </radialGradient>
            <linearGradient id="paint8_linear_operatoravatar" x1="19.5" y1="2" x2="20" y2="7" gradientUnits="userSpaceOnUse">
                <stop offset="0.0291981" stopColor="#9D3A26"/>
                <stop offset="0.463758" stopColor="#FB7726"/>
                <stop offset="0.861316" stopColor="#FDD64E"/>
            </linearGradient>
        </defs>
    </svg>;
}

export default React.memo(OperatorAvatar);