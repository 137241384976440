import React from 'react';
import classNames from 'classnames';
// import PropTypes from 'prop-types';

const Voice = ({state, ...functions}) => {
    return (
        <div className="justwidget--container-form-voice">
            <div className="justwidget--form">
                <div className="justwidget--actions">
                    <div className="justwidget--alice-controls">
                        <a onClick={functions.startRecognition}
                           className={classNames("justwidget--alice-controls-start", {'justwidget--hidden': !state.voiceTestStartButton || state.voiceTestListenButton || state.voiceTestStopButton})}
                        >
                            <svg className="justwidget--alice-controls-start_normal"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                <rect width="40" height="40" fill="#227fc1" opacity="0"/>
                                <path
                                    d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0ZM17,13a3,3,0,0,1,6,0v6a3,3,0,0,1-6,0Zm4,12.92V30H19V25.92A7,7,0,0,1,13,19h2a5,5,0,0,0,10,0h2A7,7,0,0,1,21,25.92Z"
                                    fill="#227fc1" fillRule="evenodd"/>
                            </svg>
                            <svg className="justwidget--alice-controls-start_hover"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                <rect width="40" height="40" fill="#227fc1" opacity="0"/>
                                <path
                                    d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,39A19,19,0,1,1,39,20,19,19,0,0,1,20,39Z"
                                    fill="#227fc1" fillRule="evenodd"/>
                                <path d="M20,22a3,3,0,0,0,3-3V13a3,3,0,0,0-6,0v6A3,3,0,0,0,20,22Z" fill="#227fc1"
                                      fillRule="evenodd"/>
                                <path
                                    d="M20,24a5,5,0,0,1-5-5H13a7,7,0,0,0,6,6.92V30h2V25.92A7,7,0,0,0,27,19H25A5,5,0,0,1,20,24Z"
                                    fill="#227fc1" fillRule="evenodd"/>
                            </svg>
                        </a>
                        <a onClick={functions.abortRecognition}
                           className={classNames('justwidget--alice-controls-listen', {'justwidget--hidden': !state.voiceTestListenButton || state.voiceTestStartButton || state.voiceTestStopButton})}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                <rect width="40" height="40" fill="#227fc1" opacity="0"/>
                                <path
                                    d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,39A19,19,0,1,1,39,20,19,19,0,0,1,20,39Z"
                                    fill="#227fc1" fillRule="evenodd"/>
                                <path d="M20,22a3,3,0,0,0,3-3V13a3,3,0,0,0-6,0v6A3,3,0,0,0,20,22Z" fill="#227fc1"
                                      fillRule="evenodd"/>
                                <path
                                    d="M20,24a5,5,0,0,1-5-5H13a7,7,0,0,0,6,6.92V30h2V25.92A7,7,0,0,0,27,19H25A5,5,0,0,1,20,24Z"
                                    fill="#227fc1" fillRule="evenodd"/>
                            </svg>
                            <div style={{color: '#4a90e2'}} className="la-ball-scale-ripple-multiple la-2x">
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </a>
                        <a onClick={functions.stopSpeech}
                           className={classNames('justwidget--alice-controls-stop', {'justwidget--hidden': !state.voiceTestStopButton || state.voiceTestStartButton || state.voiceTestListenButton})}
                        >
                            <div className="stop-icon">
                                <svg className="stop-icon_normal" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 40 40">
                                    <rect width="40" height="40" fill="#f70" opacity="0"/>
                                    <path d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm5,25H15V15H25Z"
                                          fill="#227fc1"/>
                                </svg>
                                <svg className="stop-icon_hover" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 40 40">
                                    <rect width="40" height="40" fill="#f70" opacity="0"/>
                                    <path
                                        d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,39A19,19,0,1,1,39,20,19,19,0,0,1,20,39ZM15,15H25V25H15Z"
                                        fill="#227fc1"/>
                                </svg>
                            </div>
                            <div style={{color: '#4a90e2'}} className="la-ball-scale-multiple la-2x">
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </a>
                    </div>
                    <div
                        className={classNames('justwidget--alice-no-support', {'justwidget--hidden': !state.voiceTestNotSupported})}>
                        <p>Voice test is not supported by your browser</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

Voice.propTypes = {};

export default Voice;
