import {commonLocalization} from "./common.loc";

const localsNameReplaceMap = { en: 'eng' };

class localize {
  constructor(attrs) {
    this.localization = attrs.localization;
    this.language = attrs.language.toLowerCase();
    this.defaultLanguage = attrs.defaultLanguage || 'en';
  }

  replaceVariablesWithStrings = (key, arrayArguments) => {
    let outKey = key;
    for(let i = 0; i < arrayArguments.length; i++) {
      outKey = outKey.replace(`$[${i+1}]`, arrayArguments[i]);
    }
    return outKey;
  }

  setLocale = (language) => {
    this.language = language.toLowerCase();
  }

  getLocale = () => {
    return this.language;
  }

  translate = (key, ...arrayArguments) => {

    const lang = localsNameReplaceMap[this.language] || this.language;

    if (this.localization && this.localization[key]) {
      if (lang && this.localization[key][lang]) {
        if (arrayArguments.length > 0) {
          return this.replaceVariablesWithStrings(this.localization[key][lang], arrayArguments);
        }
        return this.localization[key][lang];
      }
      if(this.localization[key][this.defaultLanguage]){
        if (arrayArguments.length > 0) {
          return this.replaceVariablesWithStrings(this.localization[key][this.defaultLanguage], arrayArguments);
        }
        return this.localization[key][this.defaultLanguage];
      }
    }
    return key
  }
}

const localization = new localize({localization: commonLocalization, language: 'en'});
export default localization;
export const translate = localization.translate;
export const t = localization.translate;