import React from 'react';

export default function DnDIcon() {
    return <svg width="54" height="54" viewBox="0 0 54 54" fill="none" style={{pointerEvents: 'none'}}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.21875 20.25C2.82078 20.25 1.6875 21.3833 1.6875 22.7813V49.7813C1.6875 51.1792 2.82078 52.3125 4.21875 52.3125H31.2188C32.6167 52.3125 33.75 51.1792 33.75 49.7813V36.2813H35.4375V49.7813C35.4375 52.1112 33.5487 54 31.2188 54H4.21875C1.8888 54 0 52.1112 0 49.7813V22.7813C0 20.4513 1.8888 18.5625 4.21875 18.5625H17.7188V20.25H4.21875Z" fill="#2A2722"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7813 1.6875C22.4365 1.6875 22.1098 1.75596 21.8125 1.87924L21.1661 0.320453C21.6647 0.113694 22.2108 0 22.7813 0H24.4688V1.6875H22.7813ZM31.2188 1.6875H27.8438V0H31.2188V1.6875ZM37.9688 1.6875H34.5938V0H37.9688V1.6875ZM44.7188 1.6875H41.3438V0H44.7188V1.6875ZM49.7813 1.6875H48.0938V0H49.7813C50.3517 0 50.8978 0.113695 51.3964 0.320453L50.75 1.87924C50.4527 1.75596 50.126 1.6875 49.7813 1.6875ZM20.4417 3.25004C20.3185 3.54733 20.25 3.87398 20.25 4.21875V5.69531H18.5625V4.21875C18.5625 3.64828 18.6762 3.10223 18.883 2.60364L20.4417 3.25004ZM52.3125 4.21875C52.3125 3.87398 52.244 3.54733 52.1208 3.25004L53.6795 2.60364C53.8863 3.10223 54 3.64828 54 4.21875V5.90625H52.3125V4.21875ZM20.25 8.64844V11.6016H18.5625V8.64844H20.25ZM52.3125 12.6563V9.28125H54V12.6563H52.3125ZM20.25 14.5547V17.5078H18.5625V14.5547H20.25ZM52.3125 19.4063V16.0313H54V19.4063H52.3125ZM20.25 20.4609V21.9375H18.5625V20.4609H20.25ZM52.3125 26.1563V22.7813H54V26.1563H52.3125ZM52.3125 31.2188V29.5313H54V31.2188C54 31.7892 53.8863 32.3353 53.6795 32.8339L52.1208 32.1875C52.244 31.8902 52.3125 31.5635 52.3125 31.2188ZM49.7813 33.75C50.126 33.75 50.4527 33.6815 50.75 33.5583L51.3964 35.117C50.8978 35.3238 50.3517 35.4375 49.7813 35.4375H48.3047V33.75H49.7813ZM32.0625 33.75H33.5391V35.4375H32.0625V33.75ZM36.4922 33.75H39.4453V35.4375H36.4922V33.75ZM42.3984 33.75H45.3516V35.4375H42.3984V33.75Z" fill="#2A2722"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.481 17.7612C37.6618 17.942 37.7509 18.1952 37.7231 18.4495L36.945 25.5681C36.8944 26.0313 36.4779 26.3658 36.0146 26.3152C35.5514 26.2645 35.2169 25.848 35.2675 25.3847L35.785 20.6504L16.4232 40.0122C16.0937 40.3417 15.5595 40.3417 15.23 40.0122C14.9005 39.6827 14.9005 39.1484 15.23 38.8189L34.5918 19.4572L29.8574 19.9746C29.3942 20.0252 28.9776 19.6908 28.927 19.2275C28.8764 18.7643 29.2109 18.3477 29.6741 18.2971L36.7927 17.519C37.047 17.4913 37.3001 17.5803 37.481 17.7612Z" fill="#2A2722"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3008 36.9414C18.4817 37.1222 18.7349 37.2113 18.9891 37.1835L26.1077 36.4054C26.571 36.3548 26.9054 35.9382 26.8548 35.475C26.8042 35.0118 26.3876 34.6773 25.9244 34.7279L19.8504 35.3918L20.5143 29.3178C20.5649 28.8546 20.2304 28.438 19.7672 28.3874C19.304 28.3368 18.8874 28.6712 18.8368 29.1345L18.0587 36.2531C18.0309 36.5073 18.12 36.7605 18.3008 36.9414Z" fill="#2A2722"/>
    </svg>

}