import React, { Component } from "react";
import axios from "axios";
import isBoolean from "lodash/isBoolean";
import mergeWith from "lodash/mergeWith";
import minBy from "lodash/minBy";
import {
  generateId,
  getCookie,
  setCookie,
  ServerError,
  scrollTo,
  findNodeInTree,
  isDev,
} from "./utils";
import autosize from "autosize";
import currentIOSVersion from "ios-version/current";
import localization from "./localization";

const { translate } = localization;
const t = translate;

const WidgetBody = require(`./themes/${process.env.REACT_APP_THEME}`);

const ONE_MONTH_IN_SECONDS = 1 * 60 * 60 * 24 * 30;

const mobilecheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const getRandomInt = function (min, max) {
  return Math.floor(Math.random() * (max + 1 - min) + min);
};

const ATTRS = {
  theme: "defaultv3",
  position: "right",

  audioMessageLimits: {
    fileSizeLimitInMb: 5,
    fileSizeLimitInSec: 30,
    requestTimeoutInSec: 30,
  },

  options: {
    notUseStyles: false,
    blinkOnNewMessage: true,
    buttonsSamePlace: false,
    voiceTest: {
      show: false,
      speaker: "oksana",
    },
    sendStartAlways: false,
    iframeMode: false,
    closeControls: {
      show: true,
    },
    headline: {
      show: false,
      text: "Chat-bot",
    },
    bubble: {
      show: false,
      bubbletext: "Ask me",
      mobileBubbletext: "Ask me",
      delay: 0,
    },
    avatar: {
      show: false,
      assistantAvatarUrl: "",
      userAvatarUrl: "",
      operatorAvatarUrl: "",
    },
    names: {
      show: false,
      assistantName: "Assistant",
      userName: "You",
      operatorDefaultName: "Operator",
    },
    linkPreview: {
      show: true,
    },
    captions: {
      close: "close",
      send: "send",
      inputPlaceholder: "Ask your question",
      loadHistory: "Загрузить историю сообщений",
      errorOccurred: "Возникла ошибка",
      viewLog: "посмотреть лог ошибки",
      errorLog: "Лог ошибки",
      copyLog: "Скопировать лог",
      closeLog: "Закрыть",
      imageDownlaod: "Закрыть",
      dndLabel: "Перетащите файл сюда",
    },
    language: "ru",
    inputMaxLen: 10000,
    fileUpload: {
      show: true,
      tooBigFileMessage: "File too big",
      uploadErrorMessage: "File load error",
    },
    sendBtn: {
      show: false,
    },
    voiceMessages: {
      show: false,
    },
    blockForm: {
      show: false,
    },
    autoHeight: false,
    logo: false,
    sessionByUserMessage: {
      show: false,
      welcomemessage: "",
      delay: 1500,
      buttonsShow: false,
      buttons: [],
    },
    involvement: {
      enabled: false,
      events: [],
    },
    positionOffset: {
      right: 24,
      bottom: 24,
    },
    font: "Roboto",
    sizes: {
      font: "small",
      avatar: "big",
    },
    palette: {
      headlineBackgroundColor: "#5A9CED",
      headlineTextColor: "dark",
      chatBackgroundColor: "#FFFFFF",
      chatBackgroundOpacity: 100,
      userMessageBackgroundColor: "#CBDFF8",
      userMessageTextColor: "dark",
      botMessageBackgroundColor: "#F4F5F5",
      botMessageTextColor: "dark",
      formButtonsColor: "#5A9CED",
      formTextColor: "#000",
    },
    soundNotification: {
      option: "none", //['client_bot', 'bot', 'client', 'none']
      sound: "",
    },
    embedmentContainer: {
      id: "",
      enabled: false,
    },
    showCloseButton: false,
    showSpinner: false,
  },
};

class JustWidget extends Component {
  source = null;
  isReady = true;
  alreadyOpened = false;
  welcomeMessageWasShow = false;
  needSendStart = true;
  involvementOpen = false;
  needFocus = true;
  sessionId = null;
  userId = null;
  connectionId = null;
  testMode = false;
  errorCounter = 0;
  attributes = {};
  total = 0;
  createdTime = null;
  historyOffset = 0;
  iOSFixClass = "ios_11_fix";
  inputFocusClassIOS = "ios_11_input_focused";

  touchClass =
    "ontouchstart" in window || navigator.msMaxTouchPoints
      ? "justwidget_touch"
      : "";
  screenshotBlob = null;

  voiceInstance = null;
  file = null;

  fileInput = null;

  pageIsBlured = false;
  pageTitle = document.title;
  titleBlinkInterval = 0;

  audioOnMessagePlayer = null;

  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      buttons: [],

      isOpen: false,
      involvementOpen: false,

      loadHistory: false,
      isHistoryLoading: false,

      image: "",
      stacktrace: "",

      mount: true,
      soundOff: false,

      unreadMessagesCount: 0,
    };
    this.attributes = !window.JustWidgetAttributes
      ? { ...ATTRS }
      : mergeWith({}, ATTRS, window.JustWidgetAttributes, (a, b) =>
          b === null ? a : undefined
        );

    localization.setLocale(
      this.attributes.options.forcedLanguage || this.attributes.options.language
    );

    if (isDev()) {
      console.log(this.attributes);
    }
    if (this.attributes.options.soundNotification.sound) {
      this.audioOnMessagePlayer = new Audio(
        this.attributes.options.soundNotification.sound
      );
    }

    this.theme = this.attributes.theme;
    this.position = this.attributes.position;
    this.name = window.JustWidgetName;
    this.accountId = window.JustWidgetAccountId;
    this.token = window.JustWidgetToken;
    window.JustWidget = {
      toggleWidget: this.toggleWidget,
      unmount: this.unmount,
      sendText: this.sendText,
      sendEvent: this.sendEvent,
    };

    try {
      /* eslint-disable */
      window.JustWidget = {
        ...window.JustWidget,
        setNewAttributes: this.setNewAttributes,
      };
    } catch (e) {
      console.log(e);
    }

    let CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
  }

  clearFile = () => (this.file = null);

  setNewAttributes = () => {
    this.attributes = mergeWith(
      {},
      ATTRS,
      window.JustWidgetAttributes,
      (a, b) => (b === null ? a : undefined)
    );

    this.theme = this.attributes.theme;
    this.position = this.attributes.position;
    this.audioOnMessagePlayer = new Audio(
      this.attributes.options.soundNotification.sound
    );
    if (this.attributes.options.forcedLanguage)
      localization.setLocale(this.attributes.options.forcedLanguage);
    if (this.state.soundOff) {
      this.audioOnMessagePlayer.muted = true;
    }
    this.setState({ ...this.state });
  };

  unmount = () => {
    this.abortRequest("Abort request when remove JustWidget from DOM");
    if (
      Boolean(this.attributes.options.voiceTest) &&
      this.attributes.options.voiceTest.show &&
      this.voiceInstance
    ) {
      this.voiceInstance.abortRecognition();
      this.voiceInstance.stopSpeech();
    }
    this.isReady = false;
    window.removeEventListener("blur", this.setIsBlured);
    window.removeEventListener("focus", this.setIsFocused);
    this.setState({
      mount: !this.state.mount,
    });
  };

  setTextInput = (domElement) => {
    this.textInput = domElement;
  };
  setImageInput = (domElement) => {
    this.fileInput = domElement;
  };

  setMessagesWrapper = (domElement) => {
    this.messagesWrapper = domElement;
  };

  setIsBlured = () => {
    this.pageIsBlured = true;
  };
  setIsFocused = () => {
    this.pageIsBlured = false;
    clearInterval(this.titleBlinkInterval);
    document.title = this.pageTitle;
  };

  startBlink = (title = "Новое сообщение!") => {
    clearInterval(this.titleBlinkInterval);
    if (this.pageIsBlured) {
      this.titleBlinkInterval = setInterval(() => {
        if (document.title !== title) {
          this.pageTitle = document.title;
        }
        document.title = document.title === title ? this.pageTitle : title;
      }, 750);
    }
  };

  initBlinkOnNewMessage = () => {
    window.addEventListener("blur", this.setIsBlured);
    window.addEventListener("focus", this.setIsFocused);
  };

  componentWillMount() {
    if (!this.attributes.options.notUseStyles) {
      require(`./themes/${process.env.REACT_APP_THEME}/scss/${process.env.REACT_APP_THEME}.scss`);
    }
  }

  componentDidMount() {
    this.setIds();
    this.setTimers();

    document.addEventListener(
      "opengraphLoaded",
      this.scrollOnOpengraphReceived
    );

    const {
      options: { involvement, closeControls, iframeMode },
    } = this.attributes;
    if (
      involvement.enabled &&
      Boolean(involvement.events) &&
      involvement.events.length > 0
    ) {
      this.startInvolvementEvents();
    }

    if (!closeControls.show && !iframeMode) {
      if (!this.state.isOpen) {
        this.toggleWidget(true);
      }
    }

    if (
      !iframeMode &&
      this.attributes.options.blinkOnNewMessage &&
      this.attributes.theme === "defaultv3"
    ) {
      this.initBlinkOnNewMessage();
    }

    if (this.state.isOpen) {
      this.subscribe();

      if (this.isIOS11() && this.textInput) {
        this.textInput.addEventListener(
          "focus",
          this.addIOSWidgetKeyboardOpenClass
        );
        this.textInput.addEventListener(
          "blur",
          this.removeIOSWidgetKeyboardOpenClass
        );
      }

      if (
        this.isIOS11() &&
        !Boolean(this.attributes.options.embedmentContainerId)
      ) {
        this.addIOSfixClass();
      }
    }

    this.isReady = true;
    let event = new Event("justwidget_ready");
    document.dispatchEvent(event);
    if (this.testMode) {
      let wEvent = new Event("justwidget_mounted");
      document.dispatchEvent(wEvent);
    }
  }

  componentWillUnmount() {
    this.abortRequest("Abort request when remove JustWidget from DOM");
    const {
      options: { voiceTest },
    } = this.attributes;
    if (Boolean(voiceTest) && voiceTest.show && this.voiceInstance) {
      this.voiceInstance.abortRecognition();
      this.voiceInstance.stopSpeech();
    }
    this.isReady = false;
    window.removeEventListener("blur", this.setIsBlured);
    window.removeEventListener("focus", this.setIsFocused);
    document.removeEventListener(
      "opengraphLoaded",
      this.scrollOnOpengraphReceived
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
      if (!this.alreadyOpened) {
        this.subscribe();
      }

      if (!this.alreadyOpened || (this.alreadyOpened && this.involvementOpen)) {
        const {
          options: { sessionByUserMessage },
        } = this.attributes;
        if (
          Boolean(sessionByUserMessage) &&
          sessionByUserMessage.show &&
          sessionByUserMessage.welcomemessage.length > 0
        ) {
          const delay = 500; /*Boolean(sessionByUserMessage.delay) ? sessionByUserMessage.delay :*/
          setTimeout(() => this.showWelcomeMsg(), delay);
        }
      }

      if (this.messagesWrapper) {
        this.messagesWrapper.addEventListener("click", this.onLinkClick);
      }

      if (this.attributes.options.autoHeight && this.textInput) {
        autosize(this.textInput);
      }

      if (
        this.isIOS11() &&
        !Boolean(this.attributes.options.embedmentContainerId)
      ) {
        this.addIOSfixClass();
      }

      if (this.isIOS11() && this.textInput) {
        this.textInput.addEventListener(
          "focus",
          this.addIOSWidgetKeyboardOpeClass
        );
        this.textInput.addEventListener(
          "blur",
          this.removeIOSWidgetKeyboardOpeClass
        );
      }
    }

    if (!this.state.isOpen) {
      if (
        this.isIOS11() &&
        !Boolean(this.attributes.options.embedmentContainerId)
      ) {
        this.removeIOSfixClass();
      }

      if (this.voiceInstance) {
        this.voiceInstance.abortRecognition();
        this.voiceInstance.stopSpeech();
      }
      if (this.textInput) {
        this.textInput.removeEventListener(
          "focus",
          this.addIOSWidgetKeyboardOpenClass
        );
        this.textInput.removeEventListener(
          "blur",
          this.removeIOSWidgetKeyboardOpenClass
        );
      }
    }
  }

  toggleSound = () => {
    this.audioOnMessagePlayer.muted = !this.state.soundOff;
    this.setState({
      soundOff: !this.state.soundOff,
    });
  };

  playSound = (type) => {
    if (
      this.attributes.options.soundNotification.option.includes(type) &&
      this.audioOnMessagePlayer
    ) {
      this.audioOnMessagePlayer.pause();
      this.audioOnMessagePlayer.currentTime = 0;
      this.audioOnMessagePlayer.play();
    }
  };

  scrollOnOpengraphReceived = () =>
    this.messagesWrapper &&
    this.scrollMessagesContainer(this.messagesWrapper.scrollHeight);

  toggleWidget = (flag, involvementOpen = false) => {
    const isOpen = isBoolean(flag) ? flag : !this.state.isOpen;

    if (isOpen) this.setState({ unreadMessagesCount: 0 });

    return this.sendData({ stateRequest: { isOpen: isOpen } }).then((r) => {
      this.involvementOpen = involvementOpen;

      this.setState({ isOpen: isOpen });

      let event = document.createEvent("CustomEvent");
      event.initCustomEvent("justwidget_toggle", false, false, isOpen);
      document.dispatchEvent(event);
    });
  };

  setIds = () => {
    let sessionId = getCookie("waSessionId"),
      userId = getCookie(`waUserId_${this.name}`),
      testMode = getCookie("waTestMode");

    if (sessionId === undefined) {
      sessionId = generateId();
      setCookie("waSessionId", `${sessionId}; max-age=${ONE_MONTH_IN_SECONDS}`);
    }

    if (userId === undefined) {
      userId = generateId();
      setCookie(
        `waUserId_${this.name}`,
        `${userId}; max-age=${ONE_MONTH_IN_SECONDS}`
      );
    }

    this.sessionId = sessionId;
    this.userId = userId;
    this.connectionId = generateId();
    this.testMode = testMode === undefined ? false : testMode;
  };

  setTimers = () => {
    this.pageStartTime = Date.now();
    localStorage.setItem(
      `waPageStartTime_${this.name}_${this.userId}`,
      this.pageStartTime.toString()
    );

    if (
      !Boolean(
        localStorage.getItem(`waSiteStartTime_${this.name}_${this.userId}`)
      )
    ) {
      this.siteStartTime = Date.now();
      localStorage.setItem(
        `waSiteStartTime_${this.name}_${this.userId}`,
        this.siteStartTime.toString()
      );
    } else {
      this.siteStartTime = new Date(
        parseInt(
          localStorage.getItem(`waSiteStartTime_${this.name}_${this.userId}`)
        )
      );
    }
  };

  startInvolvementEvents = () => {
    const now = Date.now();

    const involvementOpenWidget = () => {
      if (
        Boolean(this) &&
        this.isReady &&
        this.attributes.options.closeControls.show &&
        !this.attributes.options.iframeMode &&
        (!this.alreadyOpened ||
          (this.alreadyOpened && this.involvementOpen) ||
          (this.alreadyOpened && !this.state.isOpen))
      ) {
        if (!this.state.isOpen) {
          this.toggleWidget(true, true);
        }
      }
    };

    this.attributes.options.involvement.events.forEach(
      (involvementEvent, index) => {
        switch (involvementEvent.eventType) {
          case "website_time": {
            const websiteTimeSpend = now - this.siteStartTime;
            if (websiteTimeSpend < involvementEvent.value * 1000) {
              this.wedsiteInvolvementTimer = setTimeout(
                involvementOpenWidget,
                involvementEvent.value * 1000 - websiteTimeSpend
              );
            }
            break;
          }
          case "page_time": {
            const pageTimeSpend = now - this.pageStartTime;
            if (pageTimeSpend < involvementEvent.value * 1000) {
              this.pageInvolvementTimer = setTimeout(
                involvementOpenWidget,
                involvementEvent.value * 1000 - pageTimeSpend
              );
            }
            break;
          }
          default:
            break;
        }
      }
    );
  };

  sendRequest = (method, headers, data) => {
    const queryString = `sid=${this.sessionId}&cid=${this.connectionId}&uid=${
      this.userId
    }&tms=${+new Date()}`;
    const url = `${window.JustWidgetBasePath}/messaging-api/${this.accountId}/chatwidget/${this.token}/?${queryString}`;
    return axios({
      url: url,
      method: method,
      headers: {
        ...headers,
      },
      data: data,
      cancelToken: this.source.token,
    }).catch((thrown) => {
      if (axios.isCancel(thrown)) {
        console.log("Request canceled", thrown.message);
      } else {
        console.error(thrown.message);
      }
      throw thrown;
    });
  };

  abortRequest = (message = "") => {
    if (!!this.source) {
      this.source.cancel(message);
    }
  };

  sendStart = () => {
    if (isDev()) {
      console.log("Send /start message");
    }

    const start = window.juswidgetVariables?.start || undefined;
    const startMessage = `/start ${
      typeof start === "string" ? start : JSON.stringify(start) || ""
    }`.trim();

    if (window.JustWidgetAttributes.options.sessionInfoWebhook) {
      try {
        window.open(
          `${
            window.JustWidgetAttributes.options.sessionInfoWebhook
          }?clientID=${encodeURIComponent(
            this.userId
          )}&botID=${encodeURIComponent(this.name)}`,
          undefined,
          { menubar: false, toolbar: false }
        );
      } catch (e) {
        console.error(e);
      }
    }

    this.sendData({
      textRequest: { text: startMessage, hiddenMessage: "true" },
    });
  };

  subscribe = () => {
    // this.alreadyOpened = true;
    this.sendRequest("GET", {
      "Content-Type": "application/json;charset=UTF-8",
    })
      .then(this.prepareSubscribeResponse)
      .then(this.onSubscribeResponse)
      .catch(this.onRequestError);
  };

  sendRawData = () => {
    try {
      return window.JustWidgetSendRawData();
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  sendText = (obj, resetSession) => {
    if (!obj.hiddenMessage) {
      this.handleText(obj.text);
    }
    this.sendData({ textRequest: obj }, resetSession);
  };

  sendEvent = (obj) => {
    this.sendData({ eventRequest: obj });
  };

  sendData = (body, resetSession) => {
    if (body.textRequest || body.eventRequest || body.fileRequest) {
      this.playSound("client");
    }

    if (
      body.textRequest?.text !== "/start" &&
      !body.stateRequest?.isOpen &&
      this.attributes.options.showSpinner &&
      !body.historyRequest
    ) {
      this.showSpinner();
    }

    return this.sendRequest(
      "POST",
      { "Content-Type": "application/json;charset=UTF-8" },
      JSON.stringify({
        message: body,
        JustWidgetRawParams: {
          ...this.sendRawData(),
          resetSession,
        },
      })
    );
  };
  sendNewSession = () => {
    this.sendText({ text: t("Session has been restarted") }, true);
  };

  prepareSubscribeResponse = (res) => {
    if (res.status === 200) {
      return res.data;
    }

    if (res.status >= 500 && res.status < 600) {
      throw new ServerError();
    }
  };

  getDataType = (data) => {
    if (
      Boolean(data.text) &&
      Boolean(data.text.errorStacktrace) &&
      this.testMode
    ) {
      return "error";
      // this.handleErrorStacktrace(data.text.errorStacktrace, false, data.text.questionId);
    }
    if (data.text && data.text.tts) {
      return "tts";
    }
    if (data.state) {
      return "state";
    }
    if (data.uploadError) {
      return "uploadError";
    }
    if (data.text || data.textRequest) {
      return "text";
    }
    if (data.history) {
      return "history";
    }
    if (data.empty) {
      return "empty";
    }
  };

  onSubscribeResponse = (data) => {
    if (isDev()) {
      console.log(`Receive data:`, data);
    }
    let type = this.getDataType(data);
    switch (type) {
      // case ('tts'): {
      //     this.handleTts(data);
      //     break;
      // }
      // case ('uploadError'): {
      //     this.handleTts(data);
      //     break;
      // }
      case "error": {
        this.handleState({ messages: [data] });
        this.hideSpinner();
        break;
      }
      case "state": {
        this.handleState(data.state);
        break;
      }
      case "tts":
      case "text": {
        this.dispatchGetMessage(data);
        this.playSound("bot");
        this.handleState({ messages: [data] }, true);
        this.hideSpinner();
        break;
      }
      case "history": {
        this.handleHistory(data.history);
        break;
      }
      case "empty": {
        this.hideSpinner();
      }
      default:
        break;
    }
    this.subscribe();
  };

  showSpinner = () => {
    const spinnerTimeout = setTimeout(
      () => this.setState({ showSpinner: true }),
      2000
    );
    this.setState({ spinnerTimeout });

    setTimeout(this.hideSpinner, 18000);
  };

  hideSpinner = () => {
    if (!this.state.spinnerTimeout) {
      return;
    }
    clearTimeout(this.state.spinnerTimeout);
    this.setState({ showSpinner: false, spinnerTimeout: null });
  };

  isNeedShowStart = (messages, isOpen = false) => {
    let needSendStart = true;

    if (
      messages &&
      messages.length > 0 &&
      !this.attributes.options.sendStartAlways &&
      !this.testMode
    ) {
      if (this.lastMessageWasTwoHoursBefore(messages)) {
        if (messages.length > 1) {
          let beforeLastMessage = messages[messages.length - 2];

          needSendStart =
            Boolean(beforeLastMessage.textRequest) &&
            beforeLastMessage.textRequest.text !== "/start";
        } else {
          needSendStart = true;
        }
      } else {
        needSendStart = false;
      }
    }

    return !this.alreadyOpened && isOpen && needSendStart;
  };

  getMessagesMinTime = (messages) => {
    const min = minBy(messages, (message) => {
      if (message.textRequest) {
        if (Boolean(message.textRequest.timestamp)) {
          return message.textRequest.timestamp;
        } else if (Boolean(message.textRequest.widgetMessageTimestamp)) {
          return message.textRequest.widgetMessageTimestamp;
        } else {
          return null;
        }
      } else {
        return Boolean(message.text) ? message.text.timestamp : null;
      }
    });

    return Boolean(min.textRequest)
      ? Boolean(min.textRequest.timestamp)
        ? min.textRequest.timestamp
        : null
      : Boolean(min.text)
      ? min.text.timestamp
      : null;
  };

  handleHistory = (history) => {
    let { messages, pageSize, pageNumber, total } = history;

    let scrollTop = this.messagesWrapper.scrollTop;

    if (messages.length > 0) {
      messages = messages.reverse();
      //let renderedMessages = '';
      let hasMoreHistory = true;
      if (pageSize * (pageNumber + 1) >= total) {
        hasMoreHistory = false;
      }
      this.setState(
        {
          messages: messages.concat(this.state.messages),
          loadHistory: hasMoreHistory,
        },
        () => {
          setTimeout(this.staticMessagesContainer(scrollTop), 0);
        }
      );
    }
  };

  handleState = (state, voiceMessageInTestMode = false) => {
    let stateMessages = this.state.messages;
    let stateButtons = [];
    let hasNewMessages = false;

    if (state.messages && state.messages.length > 0) {
      let newMessages = state.messages.reverse();
      let breaker = false;
      newMessages.forEach((mess) => {
        if (
          this.state.messages.findIndex((i) => {
            if (!breaker) {
              if (mess.text && mess.text.timestamp) {
                return i.text && i.text.timestamp === mess.text.timestamp;
              }
              if (mess.textRequest && mess.textRequest.timestamp) {
                return (
                  i.textRequest &&
                  i.textRequest.timestamp === mess.textRequest.timestamp
                );
              }
            }

            return false;
          }) > -1 &&
          !breaker
        ) {
          breaker = true;
        }
      });
      if (breaker) {
        return false;
      }

      stateMessages = stateMessages.concat(newMessages);

      let last = newMessages[newMessages.length - 1];
      if (
        Boolean(last.text) &&
        Boolean(last.text.buttons) &&
        last.text.buttons.length > 0
      ) {
        stateButtons = last.text.buttons;
      }

      if (
        stateButtons.length === 0 &&
        Boolean(last) &&
        Boolean(last.text) &&
        last.text.carousel &&
        last.text.carousel.length > 0
      ) {
        const beforeLast = stateMessages[stateMessages.length - 2];
        if (
          beforeLast &&
          Boolean(beforeLast.text) &&
          Boolean(beforeLast.text.buttons) &&
          beforeLast.text.buttons.length > 0
        ) {
          stateButtons = beforeLast.text.buttons;
        }
      }

      if (stateButtons.length === 0) {
        let responseMessages = stateMessages.filter((x) => Boolean(x.text));
        let lastWithButton = responseMessages[responseMessages.length - 1];
        const text =
          lastWithButton &&
          lastWithButton.text.operatorInfo &&
          lastWithButton.text;

        if (Boolean(text) && Boolean(text.buttons) && text.buttons.length > 0) {
          stateButtons = text.buttons;
        }
      }

      if (
        Boolean(this.attributes.options.voiceTest) &&
        this.attributes.options.voiceTest.show &&
        (voiceMessageInTestMode || !this.testMode) &&
        !last.textRequest
      ) {
        newMessages.forEach((mess) => {
          if (mess.text && !mess.text.audioUrl) {
            if (mess.text.tts) {
              this.handleTts(mess.text.tts, false);
            } else if (mess.text.text) {
              this.handleTts(mess.text.text, false);
            }
          }
        });
      }

      if (stateMessages.length > 0) {
        stateMessages = stateMessages.map((message, index) => {
          if (index < stateMessages.length - 1 && message?.text?.buttons) {
            delete message?.text?.buttons;
            return { ...message };
          }
          return message;
        });
      }

      hasNewMessages = true;

      this.createdTime =
        this.createdTime || this.getMessagesMinTime(stateMessages);
    }

    if (state.remaining > 0) {
      this.setState({ loadHistory: state.remaining });
    }

    this.setState(
      {
        messages: stateMessages,
        buttons: stateButtons,
      },
      () => {
        if (hasNewMessages) {
          this.scrollMessagesContainer(this.messagesWrapper.scrollHeight);
        }
      }
    );

    this.needSendStart = this.isNeedShowStart(stateMessages, state.isOpen);

    if (this.needSendStart) {
      if (
        !(
          Boolean(this.attributes.options.sessionByUserMessage) &&
          this.attributes.options.sessionByUserMessage.show
        )
      ) {
        this.sendStart();
      }
      this.alreadyOpened = true;
      this.needFocus = false;
    }

    if (isBoolean(state.isOpen)) {
      if (this.attributes.options.closeControls.show) {
        if (this.state.isOpen !== state.isOpen) {
          this.toggleWidget(state.isOpen);
        }
      } else {
        if (!this.state.isOpen) {
          if (!state.isOpen) {
            this.toggleWidget(true);
          }
        }
      }
    }
  };

  dispatchGetMessage = (messageData) => {
    let event = document.createEvent("CustomEvent");
    event.initCustomEvent("justwidget_get_message", false, false, messageData);
    document.dispatchEvent(event);
    window.parent?.postMessage(
      JSON.stringify({
        type: "justWidget.newMessage",
        message: translate("New message event"),
      }),
      "*"
    );
    this.startBlink(translate("New message event"));

    this.setState({
      unreadMessagesCount: messageData.text.unreadMessagesCount,
    });
  };

  onRequestError = (error) => {
    if (error instanceof ServerError) {
      return;
    }
    this.errorCounter += 1;
    if (process.env.NODE_ENV === "development") {
      console.error(`Network error (total: ${this.errorCounter}): ${error}`);
      console.log(error.stack);
    }
    if (this.errorCounter < 300 && this.isReady) {
      const maxTimeout = 5000 + getRandomInt(2, 15);
      const minTimeout = 200 + getRandomInt(2, 15);
      const timeout =
        Math.min(minTimeout * this.errorCounter, maxTimeout) +
        getRandomInt(2, 15);
      setTimeout(this.subscribe, timeout);
    } else if (!this.isReady) {
      console.error("JustWidget not ready now");
    } else {
      console.error("Too many errors");
    }
  };

  buttonClick = (value) => {
    const text = typeof value === "string" ? value : value.text;

    this.handleText(text, true);
    this.removeAnswerButtons();

    this.sendData({ textRequest: { text } });
    this.scrollMessagesContainer(this.messagesWrapper.scrollHeight - 255);
  };

  removeAnswerButtons = () => {
    this.setState({
      buttons: [],
    });
  };

  focus = () => {
    if (Boolean(this.textInput)) {
      this.textInput.focus();
    }
  };

  scrollMessagesContainer = (scrollHeight) => {
    scrollTo(this.messagesWrapper, scrollHeight, 100);
    if (!mobilecheck()) {
      this.focus();
    }
  };

  staticMessagesContainer = (scrollTop) => {
    let iterator = this.state.loadHistory ? 1 : 0;
    let children = this.messagesWrapper.children;
    let height = 0;
    for (
      iterator;
      iterator <= 9 + (this.state.loadHistory ? 1 : 0);
      iterator += 1
    ) {
      height += Boolean(children[iterator])
        ? children[iterator].clientHeight
        : 0;
    }
    this.messagesWrapper.scrollTop = height + scrollTop;
  };

  handleText = (text) => {
    const state = {
      messages: [
        {
          textRequest: {
            text: text,
            widgetMessageTimestamp: Date.now(),
          },
        },
      ],
    };

    this.handleState(state);

    if (this.textInput) {
      this.textInput.value = "";
    }
  };

  showImageOverlay = (url) => {
    this.setState({
      image: url,
    });
  };

  hideImageOverlay = () => {
    this.setState({
      image: "",
    });
  };

  showErrorOverlay = (error) => {
    if (window.JustWidget.showErrorOverlay)
      return window.JustWidget.showErrorOverlay(error);
    this.setState({
      stacktrace: error,
    });
  };

  hideErrorOverlay = () => {
    this.setState({
      stacktrace: null,
    });
  };

  onLinkClick = (e) => {
    const link = findNodeInTree(e.target, "a");
    if (link && link.classList.contains("quicklink")) {
      e.preventDefault();
      const text = link.textContent;
      this.handleText(text, true);
      this.sendData({ textRequest: { text: text } });
      this.scrollMessagesContainer(this.messagesWrapper.scrollHeight - 255);
    }
  };

  onAnswerButtonClick = (e) => {
    if (Boolean(e) && e.type && e.type === "submit") {
      e.preventDefault();
      e.stopPropagation();
    }

    let text = this.textInput.value.trim();
    if (text.length > 0) {
      if (
        Boolean(this.attributes.options.voiceTest) &&
        this.attributes.options.voiceTest.show &&
        this.voiceInstance
      ) {
        this.voiceInstance.abortRecognition();
      }
      this.handleText(text, true);

      const userMsg = { textRequest: { text: text } };

      if (
        Boolean(this.attributes.options.sessionByUserMessage) &&
        this.attributes.options.sessionByUserMessage.show
      ) {
        const newMessages = [userMsg];
        const messages = this.state.messages.concat(newMessages);
        if (Boolean(messages) && messages.length > 0) {
          let lastMessage = messages[messages.length - 1];
          if (messages.length <= 2 && Boolean(lastMessage.textRequest)) {
            //send start one only if it was send 1 textRequest or welcomeMsg + 1 textRequest
            if (messages.length === 2 ? Boolean(messages[0].text) : true) {
              //{"textRequest": {"text": text, "startNow": true}}
              userMsg.textRequest.startNow = true;
            }
          }
        }
      }

      this.sendData(userMsg);

      this.scrollMessagesContainer(this.messagesWrapper.scrollHeight - 255);

      if (this.attributes.options.autoHeight && this.textInput) {
        autosize(this.textInput);
      }
    }

    if (this.file) {
      this.uploadImageBlob();
    }
  };

  loadHistory = () => {
    if (this.state.isHistoryLoading) {
      return;
    }
    this.setState({
      isHistoryLoading: true,
    });
    return this.sendData({
      historyRequest: {
        pageSize: 10,
        pageNumber: this.historyOffset,
        dateTo: this.createdTime,
      },
    }).then(() => {
      this.historyOffset += 1;
      this.setState({
        isHistoryLoading: false,
      });
    });
  };

  handleTts = (tts, setState = true) => {
    const lang =
      Boolean(this.attributes.options.language) &&
      this.attributes.options.language.toLowerCase() === "en"
        ? "en-US"
        : "ru-RU";
    let voiceUrl = `${window.JustWidgetBasePath}/restapi/tts?speaker=${
      this.attributes.options.voiceTest.speaker
    }&lang=${lang}&text=${setState ? tts.text.tts : tts}`;

    if (this.voiceInstance) {
      if (!this.voiceInstance.state.voiceGeneration) {
        if (!this.voiceInstance.state.voiceRecognizing) {
          this.voiceInstance.playVoice(voiceUrl);
        }
      } else {
        this.voiceInstance.addTextInVoiceQueue(voiceUrl);
      }
    }
  };

  getVoiceInstance = (i) => {
    this.voiceInstance = i;
  };

  //iOS
  setJustWidget = (jw) => {
    this.wrapper = jw;
  };

  isIOS11 = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return currentIOSVersion && currentIOSVersion.major === 11;
    }
  };

  addIOSWidgetKeyboardOpenClass() {
    this.wrapper.classList.add(this.inputFocusClassIOS);
  }

  removeIOSWidgetKeyboardOpenClass() {
    this.wrapper.classList.remove(this.inputFocusClassIOS);
  }

  addIOSfixClass() {
    this.wrapper.classList.add(this.iOSFixClass);
    document.documentElement.classList.add(this.iOSFixClass);
  }

  removeIOSfixClass() {
    this.wrapper.classList.remove(this.iOSFixClass);
    document.documentElement.classList.remove(this.iOSFixClass);
  }

  //File

  setActionsWrapper = (i) => {
    this.actionsWrapper = i;
  };

  onUploadSuccess = (response) => {
    const link = response.data.link;
    this.handleText(link);
    this.sendData({ fileRequest: { file: { url: link } } });
    this.file = null;
    this.fileInput.value = "";
  };

  onUploadError = (error) => {
    let errorText;
    if (error.status === 413) {
      errorText = Boolean(this.attributes.options.fileUpload)
        ? this.attributes.options.fileUpload.tooBigFileMessage
        : error.data.error;
    } else {
      errorText = Boolean(this.attributes.options.fileUpload)
        ? this.attributes.options.fileUpload.uploadErrorMessage
        : "File load error";
    }
    this.fileInput.value = "";
    this.handleState({
      messages: [{ uploadError: errorText, timestamp: new Date().getTime() }],
    });
    this.onRequestError(error);
  };

  onFileSelect = async () => {
    this.file = this.fileInput.files[0];

    if (!this.file) return;
    const response = await this.sendFileToStorage(this.file);

    try {
      await this.onUploadSuccess(response);
    } catch (e) {
      this.onUploadError(e.response);
    }
  };

  sendFileToStorage = async (progressCallback) => {
    this.file = this.fileInput.files[0];
    if (!this.file) return;

    try {
      return this.uploadFile(progressCallback);
    } catch (e) {
      this.onUploadError(e.response);
    }
  };

  onPaste = async (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    this.file = items?.[0]?.getAsFile() || null;

    const fileList = new DataTransfer();
    fileList.items.add(this.file);
    this.fileInput.files = fileList.files;
    this.onFileSelect();
  };

  uploadImageBlob = () => this.uploadFile().then(this.onUploadSuccess);

  uploadVoice = async (file) => {
    this.file = file;
    const response = await this.uploadFile();

    this.file = null;
    this.handleText(response.data.link);
    await this.sendData({
      fileRequest: { file: { url: response.data.link, type: "voice" } },
    });
  };

  uploadFile = (onUploadProgress) => {
    const url = `${window.JustWidgetBasePath}/restapi/file/upload`;
    const botId = this.name;

    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("botId", botId);

    return axios.post(url, formData, {
      onUploadProgress,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    });
  };

  onClearText = (e) => {
    const key = e.which || e.keyCode;
    if ((key === 8 || key === 46) && this.textInput.value === "") {
      this.clearFile();
    }

    if (key === 13) {
      e.preventDefault();
      this.onAnswerButtonClick();
    }
  };

  showWelcomeMsg = () => {
    if (
      this.welcomeMessageWasShow &&
      !this.lastMessageWasTwoHoursBefore(this.state.messages)
    )
      return;

    this.welcomeMessageWasShow = true;

    const msg = {
      text: {
        text: this.attributes.options.sessionByUserMessage.welcomemessage,
        timestamp: new Date().getTime(),
        hiddenMessage: false,
        tts: null,
      },
    };

    if (
      this.attributes.options.sessionByUserMessage.buttonsShow &&
      this.attributes.options.sessionByUserMessage.buttons.length > 0
    ) {
      msg.text.buttons =
        this.attributes.options.sessionByUserMessage.buttons.map((button) =>
          !!button.url ? button : button.text || button
        );
    }

    this.dispatchGetMessage(msg);
    this.handleState({ messages: [msg] }, true);
  };

  lastMessageWasTwoHoursBefore = (messages) => {
    let lastMessage = messages[messages.length - 1];
    let lastMessageTimestamp = null;
    if (lastMessage.text) {
      lastMessageTimestamp = lastMessage.text.timestamp;
    } else if (lastMessage.textRequest) {
      lastMessageTimestamp = lastMessage.textRequest.timestamp;
    }

    let currentTimestamp = Date.now();
    return (
      Boolean(lastMessageTimestamp) &&
      currentTimestamp - lastMessageTimestamp > 7200000
    );
  };

  render() {
    if (this.state.mount) {
      return (
        <WidgetBody.default
          state={{ ...this.state }}
          testMode={this.testMode}
          data={this.attributes}
          wrapperRef={this.wrapper}
          touchClass={this.touchClass}
          previewClass={this.previewClass}
          functions={{
            sendStart: this.sendStart,
            buttonClick: this.buttonClick,
            toggleWidget: this.toggleWidget,
            onAnswerButtonClick: this.onAnswerButtonClick,
            showImageOverlay: this.showImageOverlay,
            hideImageOverlay: this.hideImageOverlay,
            showErrorOverlay: this.showErrorOverlay,
            hideErrorOverlay: this.hideErrorOverlay,
            loadHistory: this.loadHistory,
            sendData: this.sendData,
            sendNewSession: this.sendNewSession,
            sendText: this.sendText,
            handleText: this.handleText,
            onPaste: this.onPaste,
            onFileSelect: this.onFileSelect,
            sendFileToStorage: this.sendFileToStorage,
            uploadVoice: this.uploadVoice,
            onClearText: this.onClearText,
            clearFile: this.clearFile,
          }}
          getDomFunctions={{
            setTextInput: this.setTextInput,
            setImageInput: this.setImageInput,
            setMessagesWrapper: this.setMessagesWrapper,
            setActionsWrapper: this.setActionsWrapper,
            setJustWidget: this.setJustWidget,
          }}
          getInstances={{
            getVoiceInstance: this.getVoiceInstance,
          }}
          soundControl={{
            soundVisible:
              this.attributes.options.soundNotification.option !== "none",
            soundOff: this.state.soundOff,
            toggleSound: this.toggleSound,
          }}
        />
      );
    }

    return null;
  }
}

JustWidget.propTypes = {};

export default JustWidget;
