import React from "react";
import classNames from "classnames";

const RecordTimer = ({ seconds, isStopRecIcon }) => {
  const secondsToFormatedTime = () => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, "0") || "00";
    const minutes =
      String(Math.floor((seconds % 3600) / 60)).padStart(2, "0") || "00";
    const secs = String(seconds % 60).padStart(2, "0") || "00";

    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <div className="justwidget--record__voice">
      <div
        className={classNames("justwidget--rec__icon", {
          "justwidget--rec__icon--stop": isStopRecIcon,
        })}
      />
      <div>{secondsToFormatedTime()}</div>
    </div>
  );
};

export default RecordTimer;
