import React from 'react';

export default function LinkIcon () {
    return <svg className="justwidget--link-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
         role="presentation">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero"/>
            <path
                  d="M7,6H8V7A1,1,0,0,1,7,8H1A1,1,0,0,1,0,7V1A1,1,0,0,1,1,0H2V1H1V7H7ZM4,0V1H6.29L3.15,4.15a.48.48,0,0,0,0,.7.48.48,0,0,0,.7,0L7,1.71V4H8V0Z" fill="var(--form-buttons-color)"/>
        </g>
    </svg>
}