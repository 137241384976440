import React, {useMemo} from 'react';
import moment from "moment";
import localization from "../../../../localization";
const t = localization.translate;

const SystemMessage = ({ text,  timestamp }) => {
    const textSystemMessage = useMemo(() => {
        const {operatorInfo, systemMessage: { systemMessageType, fromOperatorGroup }={}} = text || {};
        const time = moment(timestamp).format("HH:mm");
        switch (systemMessageType) {
            case 'NEW_OPERATOR_CHAT':
                return `${t('Analytics:ClientDialog:Connecting_to_a_chat_with_an_operator')} ${time}`;
            case 'OPERATOR_JOINED':
                const { id, firstName, lastName } = operatorInfo || {};
                return `${ t('Analytics:ClientDialog:Operator_is_connected', firstName || lastName || id)} ${time}`;
            case 'OPERATOR_LEFT':
                return `${t('Analytics:ClientDialog:The_operator_ended_the_dialogue')} ${time}`;
            case 'CLIENT_LEFT':
                return `${t('Analytics:ClientDialog:The_client_has_completed_the_dialogue')} ${time}`;
            case 'TRANSFER_TO_OPERATOR_GROUP': {
                return `${t('Analytics:ClientDialog:Chat_is_transferred_to', fromOperatorGroup)} ${time}`;
            }
            case 'OPERATOR_CHAT_COMPLETED':
                return `${t('Analytics:ClientDialog:Chat_with_the_operator_is_complete')} ${time}`;
            case 'TRANSFER_AFTER_OPERATOR_SILENCE':
                return `${t('Analytics:ClientDialog:Switching_to_another_operator')} ${time}`;
            case 'CLIENT_BLOCKING_BY_OPERATOR':
                return `${t('Analytics:ClientDialog:The_client_is_blocked_in_operator_chat')} ${time}`;
            case 'CLIENT_UNBLOCKING_BY_OPERATOR':
                return `${t('Analytics:ClientDialog:The_client_is_unblocked_in_operator_chat')} ${time}`;
            default:
                return null;
        }
    }, [text, timestamp]);

    return (
        <div className='justwidget--system-message'>
              <span>{textSystemMessage}</span>
        </div>
    )
};

export default SystemMessage;